import React, { useEffect, useMemo, useState } from 'react'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { Box, Button, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useSideEffect } from '../../data/store'
import { creditEffects, creditSelectors } from '../../data/store/CreditApplication'
import DataGridHeader from './DataGridHeader'
import { CreditApplication } from '../../data/types'
import { appSelectors } from '../../data/store/AppStore'
import { formatDate } from '../../services/Formatter'

const falsy = () => false
const fifty = [50]

export default function BrowseCreditApplicationsPage(): JSX.Element {
  const { t } = useTranslation()

  const dispatchEffect = useSideEffect()

  const [offset, setOffset] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number>(0)
  const [hasMore, setHasMore] = useState<boolean>(true)

  const creditApplications = useAppSelector(creditSelectors.getList)
  const merchantsById = useAppSelector(appSelectors.getMerchantsByIds)
  const isLoading = useAppSelector(creditSelectors.isLoadingList)

  useEffect(() => {
    dispatchEffect(creditEffects.getByFilters({ limit: 50, offset })).then((data: CreditApplication[]) => {
      if (data.length > 0) {
        setPageCount((prev) => prev + 1)
        setPageIndex(pageCount)
      } else {
        setHasMore(false)
      }
    })
  }, [dispatchEffect, offset])

  const handleLoadMore = () => {
    if (hasMore) setOffset((prev) => prev + 50)
  }

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'createdOn',
        headerName: t('browseCreditApplications.dataGridColumnHeaders.createdOn'),
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => formatDate(params.row.createdOn),
      },
      {
        field: 'dtReferenceId',
        headerName: t('browseCreditApplications.dataGridColumnHeaders.applicationNumber'),
        flex: 1,
        renderCell: (params: GridValueGetterParams) => {
          return <Link href={`/credit-applications/${params.row.id}/edit`}>{params.row.dtReferenceId}</Link>
        },
      },
      {
        field: 'merchantId',
        headerName: t('browseCreditApplications.dataGridColumnHeaders.merchant'),
        flex: 2,
        valueGetter: (params: GridValueGetterParams) => merchantsById[params.row.merchantId]?.name,
      },
      {
        field: 'applicant.firstName',
        headerName: t('browseCreditApplications.dataGridColumnHeaders.client'),
        flex: 2,
        flexGrow: 1,
        renderCell: (params: GridValueGetterParams) => {
          return (
            <span>{`${params.row.applicant.firstName || ''} ${params.row.applicant.lastName || ''} ${
              params.row.coApplicant?.firstName || ''
            } ${params.row.coApplicant?.lastName || ''}`}</span>
          )
        },
      },
      { field: 'status', headerName: t('common.status'), flex: 1 },
    ],
    [t],
  )

  return (
    <div>
      <DataGridHeader />
      <Box sx={{ textAlign: 'center' }}>
        <div style={{ height: '700px' }}>
          <DataGrid
            page={pageIndex}
            onPageChange={(page: number) => setPageIndex(page)}
            isRowSelectable={falsy}
            rows={creditApplications}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={fifty}
            loading={isLoading}
          />
          <Button onClick={handleLoadMore}>Load more</Button>
        </div>
      </Box>
    </div>
  )
}
