import { IconButton, Stack, Typography, Paper, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Edit as EditIcon } from '@mui/icons-material'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import { FieldErrors } from 'react-hook-form'
import { CopyMessage } from '../../../../../../services/strings'
import { Vehicle } from '../../../../../../data/types'
import { formatCar, FormatCurrency } from '../../../../../../services/Formatter'

type Props = {
  onOpenVehiculeUpdateDialog: () => void
  vehicule: Vehicle | undefined
  errors: FieldErrors | undefined
}

export default function VehiculeIdentification({ onOpenVehiculeUpdateDialog, errors, vehicule }: Props) {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Paper
      elevation={3}
      sx={{ borderRadius: 2, p: 2, mb: 3, border: errors !== undefined ? `2px solid ${theme.palette.error.main}` : '' }}
    >
      <Stack direction="row" spacing={{ xs: 1, sm: 3 }} justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center">
          <Typography>
            {t('worksheet.vin')}: {vehicule?.vin}
          </Typography>
          <IconButton onClick={() => CopyMessage(vehicule?.vin ?? '')}>
            <CopyAllIcon />
          </IconButton>
        </Stack>
        <Typography>KM: {vehicule?.mileage}</Typography>
        <Typography>
          {t('worksheet.condition')}: {t(`worksheet.${vehicule?.condition}`)}
        </Typography>
        {vehicule?.condition === 'new' && (
          <Typography>
            {t('worksheet.msrp')}: {FormatCurrency(vehicule?.msrp)}
          </Typography>
        )}
        <IconButton onClick={() => onOpenVehiculeUpdateDialog()}>
          <EditIcon fontSize="large" color="primary" />
        </IconButton>
      </Stack>

      <Typography sx={{ mt: 1 }}>Model: {formatCar(vehicule)}</Typography>
    </Paper>
  )
}
