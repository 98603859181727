import React from 'react'
import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { BusinessError } from '../data/types'

type Props = {
  errors: BusinessError[]
}

export default function PageError({ errors }: Props): JSX.Element | null {
  const { t } = useTranslation()
  if (errors.length === 0) return null
  return (
    <div>
      {errors.map((error) => (
        <Alert severity="error" key={error.message}>
          {t(error.message, error.params)}
        </Alert>
      ))}
    </div>
  )
}
