/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { FormHelperText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FieldError } from 'react-hook-form'
import translateErrorCode from './TranslateError'
import { SelectValueListItem } from '../data/types'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type Props = {
  title: string
  content: Array<SelectValueListItem>
  error?: FieldError
  defaultValues?: string[] | number[]
  disabled?: boolean
}

const MultipleSelectCheckmarks = React.forwardRef<Props, Props>(function MultipleSelectCheckmarks(
  { title, content, error, defaultValues, disabled, ...otherProps }: Props,
  ref: React.Ref<any>,
) {
  const [selectedElements, setSelectedElements] = React.useState<string[] | number[]>(defaultValues ?? [])

  const { t } = useTranslation()

  React.useEffect(() => {
    if (defaultValues) {
      setSelectedElements(defaultValues)
    }
  }, [defaultValues])

  const handleChange = (event: SelectChangeEvent<typeof selectedElements>) => {
    const {
      target: { value },
    } = event
    setSelectedElements(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  return (
    <div>
      <FormControl fullWidth sx={{ minWidth: 120 }} disabled={disabled}>
        <InputLabel id="multiple-checkbox-label" shrink>
          {title}
        </InputLabel>
        <Select
          inputRef={ref}
          inputProps={{ ...otherProps }}
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={selectedElements}
          onChange={handleChange}
          input={<OutlinedInput notched label={title} />}
          renderValue={(selected) => {
            const labels: any[] = []
            selected.forEach((item) => {
              labels.push(content.find((x) => x.value === item)?.label)
            })
            return labels.join(', ')
          }}
          MenuProps={MenuProps}
        >
          {content.map((element) => (
            <MenuItem key={element.value} value={element.value}>
              <Checkbox checked={selectedElements.findIndex((x: string | number) => x === element.value) > -1} />
              <ListItemText primary={t(`${element.label}`)} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{error ? t(translateErrorCode(error)) : ' '}</FormHelperText>
      </FormControl>
    </div>
  )
})

MultipleSelectCheckmarks.defaultProps = {
  error: undefined,
  defaultValues: [],
  disabled: false,
}

export default MultipleSelectCheckmarks
