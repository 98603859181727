import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { AxiosInstance } from 'axios'
import { TRootState } from '..'
import api from '../../api'
import { appActions } from './app-store'

export const appStoreEffects = {
  initialize: () => {
    return async (
      apiClient: AxiosInstance,
      dispatch: ThunkDispatch<TRootState, undefined, AnyAction> /* select: TypedUseSelectorHook<TRootState>, */,
    ): Promise<void> => {
      // log api is loading
      dispatch(appActions.setInitializing(true))

      // perform actual request
      await Promise.all([api.config.getMerchants(apiClient).then((msg) => dispatch(appActions.setMerchants(msg)))])
      dispatch(appActions.setInitializing(false))
      return Promise.resolve()
    }
  },
}
