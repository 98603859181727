import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TRootState } from '..'
import { Decision } from '../../types/Decision'
import { apiStateSelectors } from '../ApiStateStore'

export const decisionSlice = createSlice({
  name: 'decision',
  initialState: {
    current: null as Decision | null,
    decisionHistories: [] as Decision[],
  },
  reducers: {
    setCurrent: (state, action: PayloadAction<Decision | null>) => {
      state.current = action.payload
    },
    setHistoryList: (state, action: PayloadAction<Decision[]>) => {
      state.decisionHistories = action.payload
    },
  },
})

export default decisionSlice.reducer

export const decisionActions = decisionSlice.actions

export const GET_DECISION_BY_ID = 'decision/getById'

export const decisionSelectors = {
  isLoadingDecision: (state: TRootState) => apiStateSelectors.isLoading(state, GET_DECISION_BY_ID),
  getCurrent: (state: TRootState) => state.decision.current,
}
