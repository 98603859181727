import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Alert,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import { Vehicle, VehicleSchema } from '../../../../../../data/types/WorksheetSchema'
import { ActionsDialog, InputTextField, MultipleSelectCheckmarks, SelectComponent } from '../../../../../../components'
import { Enum, SelectValueListItem, User } from '../../../../../../data/types'
import vehicleDecoderApi from '../../../../../../data/api/vehicle-decoder/vehicle-decoder-api'
import { useAppSelector } from '../../../../../../data/store'
import { formatCar } from '../../../../../../services/Formatter'
import { userSelectors } from '../../../../../../data/store/UserStore'
import { appSelectors } from '../../../../../../data/store/AppStore'
import { reportErrorToConsole } from '../../../../../../services/error-logger'

type Props = {
  open: boolean
  title: string
  onConfirm: (data: Vehicle) => void
  onCancel: () => void
  defaultValue: Vehicle | undefined
  isTradeIn?: boolean
}

function EditVehicleDialog({ onConfirm, onCancel, open, title, defaultValue, isTradeIn }: Props) {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Vehicle>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: { ...defaultValue, isTradeIn },
    resolver: yupResolver(VehicleSchema),
  })

  const { t } = useTranslation()
  const user = useAppSelector(userSelectors.getUser) as User
  const apiClient = useAppSelector(appSelectors.getApiClient)

  const [decodedVehicles, setDecodedVehicles] = useState<Vehicle[]>([])
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>(defaultValue)
  const [notFound, setNotFound] = useState(false)

  const resetVehicle = () => {
    const tempVehicle = {
      ...VehicleSchema.getDefault(),
      vin: getValues('vin') as string,
      isTradeIn: getValues('isTradeIn') as boolean,
    }
    reset(tempVehicle)
  }

  const decodeVin = async () => {
    const vin = getValues('vin')
    if (vin?.length === 17) {
      resetVehicle()
      await vehicleDecoderApi
        .decode(apiClient, vin, user.id)
        .then((ret) => {
          if (ret[0]) {
            const first = ret[0]
            if (isTradeIn) first.isTradeIn = true
            first.vin = vin
            setDecodedVehicles(ret)
            setSelectedVehicle(first)
            reset(first)
            setNotFound(false)
          } else {
            setNotFound(true)
          }
        })
        .catch(() => {
          setSelectedVehicle(undefined)
          setDecodedVehicles([])
          setNotFound(true)
          reset({ ...defaultValue, vin })
        })
    }
  }

  React.useEffect(() => {
    reset({ ...defaultValue, isTradeIn })
  }, [defaultValue, isTradeIn])

  const handleVehicleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const vehicle = decodedVehicles.find((v) => v.id && v.id.toString() === event.target.value) as Vehicle
    vehicle.vin = getValues('vin')
    if (isTradeIn) vehicle.isTradeIn = true
    setSelectedVehicle(vehicle)
    reset(vehicle)
  }

  React.useEffect(() => {
    const condition = getValues('condition')
    if (condition === 'used') setValue('msrp', null)
  }, [watch('condition')])

  return (
    <ActionsDialog
      title={title}
      open={open}
      onCancel={() => {
        setDecodedVehicles([])
        setSelectedVehicle(defaultValue ?? VehicleSchema.getDefault())
        setNotFound(false)
        reset(defaultValue)
        onCancel()
      }}
      onConfirm={handleSubmit<Vehicle>(onConfirm, reportErrorToConsole)}
    >
      <Grid container marginTop={2} spacing={2}>
        <Grid item xs={2.5}>
          <InputTextField
            inputProps={{ style: { textTransform: 'uppercase' } }}
            label={t('worksheet.vin')}
            {...register('vin')}
            error={errors?.vin}
          />
        </Grid>
        <Grid item xs={0.5}>
          <IconButton onClick={decodeVin}>
            <SearchIcon />
          </IconButton>
        </Grid>
        <Grid item xs={9}>
          {notFound ? (
            <Alert severity="error">{t('worksheet.vehicleNotFound')}</Alert>
          ) : (
            <FormControl>
              {decodedVehicles.length > 0 && (
                <Typography variant="body1">{t('worksheet.availableVehicles')}</Typography>
              )}
              <RadioGroup aria-labelledby="vehicles-label" onChange={(event) => handleVehicleChange(event)}>
                <Stack>
                  {decodedVehicles.map((v) => (
                    <FormControlLabel
                      checked={selectedVehicle?.id === v.id}
                      key={v.id}
                      value={v.id}
                      control={<Radio />}
                      label={formatCar(v)}
                    />
                  ))}
                </Stack>
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={2.5}>
          <InputTextField label={t('worksheet.mileage')} {...register('mileage')} error={errors?.mileage} />
        </Grid>
        {!isTradeIn && (
          <Grid item xs={2.5}>
            <SelectComponent
              content={Object.values(Enum.ECarCondition).map((item) => {
                const data: SelectValueListItem = { label: `worksheet.${item}`, value: item }
                return data
              })}
              label="Condition"
              {...register('condition')}
              error={errors?.condition}
            />
          </Grid>
        )}
        {!isTradeIn && (
          <Grid item xs={2.5}>
            <MultipleSelectCheckmarks
              title={t('worksheet.includes')}
              content={
                selectedVehicle?.includes.map((item) => {
                  const data: SelectValueListItem = { label: item, value: item }
                  return data
                }) ?? []
              }
              {...register('includes')}
              defaultValues={selectedVehicle?.includes}
            />
          </Grid>
        )}
        {!isTradeIn && (
          <Grid item xs={2.5}>
            <MultipleSelectCheckmarks
              title={t('worksheet.additions')}
              content={
                selectedVehicle?.additions.map((item) => {
                  const data: SelectValueListItem = { label: item, value: item }
                  return data
                }) ?? []
              }
              {...register('additions')}
              defaultValues={selectedVehicle?.additions}
            />
          </Grid>
        )}
        <Grid item xs={2}>
          <InputTextField label={t('worksheet.year')} {...register('year')} error={errors?.year} />
        </Grid>
        <Grid item xs={2.5}>
          <InputTextField label={t('worksheet.make')} {...register('make')} error={errors?.make} />
        </Grid>
        <Grid item xs={2.5}>
          <InputTextField label={t('worksheet.model')} {...register('model')} error={errors?.model} />
        </Grid>
        {!isTradeIn && (
          <Grid item xs={2.5}>
            <InputTextField label={t('worksheet.series')} {...register('series')} error={errors?.series} />
          </Grid>
        )}
        {!isTradeIn && (
          <Grid item xs={2.5}>
            <InputTextField label={t('worksheet.bodyStyle')} {...register('bodyStyle')} error={errors?.bodyStyle} />
          </Grid>
        )}

        {!isTradeIn && (
          <Grid item xs={2}>
            <InputTextField label={t('worksheet.msrp')} {...register('msrp')} error={errors?.msrp} />
          </Grid>
        )}

        {/* Trade-In */}
        {isTradeIn && (
          <>
            <Grid item xs={2.5}>
              <InputTextField label={t('worksheet.allowance')} {...register('allowance')} error={errors?.allowance} />
            </Grid>

            <Grid item xs={3}>
              <InputTextField
                label={t('worksheet.lienAmount')}
                {...register('lienAmount')}
                error={errors?.lienAmount}
              />
            </Grid>

            <Grid item xs={9}>
              <InputTextField
                label={t('worksheet.balanceOwedTo')}
                {...register('balanceOwedTo')}
                error={errors?.balanceOwedTo}
              />
            </Grid>
          </>
        )}
      </Grid>
    </ActionsDialog>
  )
}

EditVehicleDialog.defaultProps = {
  isTradeIn: false,
}

export default React.memo(EditVehicleDialog)
