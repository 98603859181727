import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Breadcrumb, PageSpinner } from '../../components'
import { creditEffects, creditSelectors } from '../../data/store/CreditApplication'
import { CreditApplication } from '../../data/types'
import CreditApplicationForm from './components/CreditApplicationForm/CreditApplicationForm'
import { useAppSelector, useSideEffect } from '../../data/store'
import { reportErrorToServer } from '../../services/error-logger'
import { decisionEffects } from '../../data/store/Decision/decision-effects'

export default function EditCreditApplicationPage(): JSX.Element {
  const { t } = useTranslation()
  const dispatchEffect = useSideEffect()
  const navigate = useNavigate()
  const { id } = useParams()
  const isLoading = useAppSelector(creditSelectors.isLoadingCreditApplication)

  const creditApplication = useAppSelector(creditSelectors.getCurrent)

  useEffect(() => {
    if (id) {
      dispatchEffect(creditEffects.getById(id)).catch(() => {
        navigate('/notfound', { replace: true })
      })
      dispatchEffect(decisionEffects.getById(id))
    }
  }, [dispatchEffect, id])

  const breadCrumbs = [
    { path: '/', label: t('breadcrumbs.home') },
    { path: '/credit-applications/browse', label: t('breadcrumbs.creditApplication') },
    { path: '#', label: t('breadcrumbs.application').concat(` #${creditApplication?.dtReferenceId}`) },
  ]

  const onSave = (data: Partial<CreditApplication>) => {
    dispatchEffect(creditEffects.update(data))
      .then(() => {
        navigate('/credit-applications/browse')
      })
      .catch(reportErrorToServer)
  }

  return (
    <>
      <PageSpinner isLoading={isLoading} />
      {!isLoading && creditApplication && (
        <>
          <Breadcrumb trees={breadCrumbs} />
          <CreditApplicationForm creditApplication={creditApplication} onSave={onSave} />
        </>
      )}
    </>
  )
}
