import * as yup from './common/yup-extended'
import yupExtInt from './common/SchemaTypes'
import { Enum } from './constants'

const vehicleSchema = {
  id: yup.default.string(),
  isTradeIn: yup.default.boolean().default(false),

  // Common
  year: yupExtInt.integer
    .positive('common.errors.positive')
    .default(0)
    .when('vin', {
      is: (value: string) => value && value.length === 17,
      then: yupExtInt.integer.positive('common.errors.positive').required().nullable(false),
    }),
  make: yup.default
    .string()
    .default('')
    .when('vin', {
      is: (value: string) => value && value.length === 17,
      then: yup.default.string().required(),
    }),
  model: yup.default
    .string()
    .default('')
    .when('vin', {
      is: (value: string) => value && value.length === 17,
      then: yup.default.string().required(),
    }),

  // New vehicle
  msrp: yupExtInt.double.when('isTradeIn', {
    is: false,
    then: yupExtInt.integer.when('condition', {
      is: (value: string) => value && value === Enum.ECarCondition.New,
      then: yupExtInt.double.positive('common.errors.positive').required(),
    }),
  }),

  // Vehicle
  additions: yup.default
    .array()
    .default([])
    .when('isTradeIn', {
      is: false,
      then: yup.default.array(yup.default.string().required().default('')),
    }),
  includes: yup.default
    .array()
    .default([])
    .when('isTradeIn', {
      is: false,
      then: yup.default.array(yup.default.string().required().default('')),
    }),
  series: yup.default.string().default('').when('isTradeIn', {
    is: false,
    then: yup.default.string().required(),
  }),
  condition: yup.default
    .string()
    .default('')
    .nullable(true)
    .when('isTradeIn', {
      is: false,
      then: yup.default.string().default('').nullable(true).required(),
    }),
  vin: yup.default
    .string()
    .default('')
    .uppercase()
    .when('isTradeIn', {
      is: false,
      then: yup.default
        .string()
        .required()
        .matches(/^[A-HJ-NPR-Za-hj-npr-z0-9]{17}$/, 'common.errors.invalidVin'), // Same as server
    }),
  mileage: yupExtInt.integer
    .positive('common.errors.positive')
    .default(0)
    .when('isTradeIn', {
      is: false,
      then: yupExtInt.integer.positive('common.errors.positive').required().nullable(false),
    }),
  bodyStyle: yup.default.string().default('').when('isTradeIn', {
    is: false,
    then: yup.default.string().required(),
  }),

  // Trade In Vehicle
  allowance: yupExtInt.double.default(0).when(['isTradeIn'], {
    is: true,
    then: yupExtInt.double.positive('common.errors.positive').required(),
  }),
  lienAmount: yupExtInt.double.positive('common.errors.positive'),
  balanceOwedTo: yup.default.string().when(['isTradeIn', 'vin'], {
    is: (isTradeIn: boolean, lienAmount: number | null) => {
      return lienAmount && isTradeIn
    },
    then: yup.default.string().required(),
  }),
  netTradeInAllowance: yupExtInt.double,

  cbbOverride: yup.default.boolean().default(false),
}
export const VehicleSchema = yup.default.object(vehicleSchema)
export type Vehicle = yup.default.InferType<typeof VehicleSchema>

const feesAndAccessoriesSchema = yup.default.object({
  installationDelivery: yupExtInt.double.positive('common.errors.positive'),
  licenseFee: yupExtInt.double.positive('common.errors.positive'),
  dealerAdminFee: yupExtInt.double.positive('common.errors.positive'),
  otherTaxable: yupExtInt.double.positive('common.errors.positive'),
  otherTaxableDescription: yup.default
    .string()
    .nullable()
    .when('otherTaxable', {
      is: (otherTaxable: number | null) => otherTaxable !== null,
      then: yup.default.string().required(),
    }),
  otherNonTaxable: yupExtInt.double.positive('common.errors.positive'),
  otherNonTaxableDescription: yup.default
    .string()
    .nullable()
    .when('otherNonTaxable', {
      is: (otherNonTaxable: number | null) => otherNonTaxable !== null,
      then: yup.default.string().required(),
    }),
  // Iceberg Fees
  icebergLenderAdminFee: yupExtInt.integer.positive('common.errors.positive'),
  icebergPPSA: yupExtInt.integer.positive('common.errors.positive'),
  vehicleAccAndFees: yupExtInt.double,
})

export const applicableTaxSchema = yup.default.object({
  taxId: yup.default.string(),
  percentage: yupExtInt.double.nullable(true),
})

export type applicableTax = yup.default.InferType<typeof applicableTaxSchema>

export const computedTaxSchema = yup.default.object({
  taxId: yup.default.string(),
  value: yupExtInt.double.nullable(true),
})

export type ComputedTax = yup.default.InferType<typeof computedTaxSchema>

const aftermarketServiceSchema = yup.default.object({
  extendedService: yupExtInt.double.positive('common.errors.positive'),
  extendedServiceDuration: yupExtInt.integer,
  gapInsurance: yupExtInt.double.positive('common.errors.positive'),
  gapInsuranceDuration: yupExtInt.integer,
  lifeInsurance: yupExtInt.double.positive('common.errors.positive'),
  accidentAndHealthInsurance: yupExtInt.double.positive('common.errors.positive'),
  taxesOnInsurance: yupExtInt.double.positive('common.errors.positive'),
  aftermarketVsValue: yupExtInt.double.positive('common.errors.positive'),
})

export const transactionSchema = yup.default.object({
  price: yupExtInt.double.positive('common.errors.positive').required(),
  rebate: yupExtInt.double.positive('common.errors.positive'),
  cashDown: yupExtInt.double.positive('common.errors.positive'),
  term: yupExtInt.integer.min(6).required(),
  paymentFrequency: yup.default.string().nullable(true).required(),
  interestRate: yupExtInt.double.positive('common.errors.positive'),
  feesAndAccessories: feesAndAccessoriesSchema.default(feesAndAccessoriesSchema.getDefault()),
  aftermarketService: aftermarketServiceSchema.default(aftermarketServiceSchema.getDefault()),
  deliveryOn: yup.default.string().default('').isValidDate(),
  firstPaymentDate: yup.default.string().default('').isValidDate(),
  isTaxExempt: yup.default.boolean().default(false),
})

export type Transaction = yup.default.InferType<typeof transactionSchema>

export const vehicleInternalEvaluation = yup.default.object({
  id: yup.default.string().nullable(true),
  vehicleValue: yupExtInt.double.positive('common.errors.positive'),
  discretionaryAllocation: yupExtInt.double
    .min(0, 'common.errors.under0')
    .lessThan(500, 'common.errors.over500')
    .default(0),
})

export type VehicleInternalEvaluation = yup.default.InferType<typeof vehicleInternalEvaluation>

export const worksheetSchema = yup.default.object({
  vehicle: VehicleSchema,
  applicableTaxes: yup.default.array(applicableTaxSchema).default([]).nullable(true),
  computedTaxes: yup.default.array(computedTaxSchema).default([]).nullable(true),
  extendedServiceTaxIn: yupExtInt.double.default(0),
  tradeInVehicle: VehicleSchema.nullable(true).default(null),
  brokerRequestedFinancingPlan: yup.default.string().nullable(true),
  transaction: transactionSchema.nullable(false).default(transactionSchema.getDefault()),
  subTotal: yupExtInt.double.default(0),
  totalAmountFinanced: yupExtInt.double.positive('common.errors.positive'),
  effectiveRate: yupExtInt.double.default(0),
  paymentFrequencyAmount: yupExtInt.double.positive('common.errors.positive'),
  tradeInTaxExemption: yupExtInt.double.default(0),
  total: yupExtInt.double.positive('common.errors.positive'),
})

export type Worksheet = yup.default.InferType<typeof worksheetSchema>
