import React from 'react'
import { Grid, Typography } from '@mui/material'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ApplicantHomeFees, CreditApplication, SelectValueListItem } from '../../../../../../../data/types'
import { Enum } from '../../../../../../../data/types/constants'
import { InputTextField, SelectComponent } from '../../../../../../../components'

type Props = {
  register: UseFormRegister<CreditApplication>
  errors: FieldErrors<ApplicantHomeFees> | undefined
  name: 'coApplicant.homeFees' | 'applicant.homeFees'
}

function ApplicantMortgage({ register, errors, name }: Props) {
  const { t } = useTranslation()
  return (
    <Grid container item xs={12} spacing={2} alignContent="space-between">
      <Grid item mt={2} md={12}>
        <Typography variant="h6" component="span">
          {t('editCreditApplication.address.mortgage.label')}
        </Typography>
      </Grid>

      <Grid item xs={12} md={3} alignContent="space-between">
        <SelectComponent
          content={Object.values(Enum.EHomeStatus).map((item) => {
            const data: SelectValueListItem = { label: `enum.eHomeStatus.${item}`, value: item }
            return data
          })}
          label={t('editCreditApplication.address.mortgage.typeId')}
          {...register(`${name}.typeId`)}
          error={errors?.typeId}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputTextField
          error={errors?.monthlyPayment}
          label={t('editCreditApplication.address.mortgage.monthlyPayment')}
          {...register(`${name}.monthlyPayment`)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputTextField
          error={errors?.houseMarketValue}
          label={t('editCreditApplication.address.mortgage.houseMarketValue')}
          {...register(`${name}.houseMarketValue`)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputTextField
          error={errors?.mortgageAmount}
          label={t('editCreditApplication.address.mortgage.mortgageAmount')}
          {...register(`${name}.mortgageAmount`)}
        />
      </Grid>

      {/* TODO: BUTTON ADRESSE ANTÉRIEURE */}
      {/* <Grid container justifyContent="flex-end" mt={2} mb={2}>
        <Button variant="outlined">{t('editCreditApplication.address.mortgage.oldAddress')}</Button>
      </Grid> */}
    </Grid>
  )
}
export default ApplicantMortgage
