import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { TRootState } from '..'

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: {
    maxTerm: 0 as number,
  },
  reducers: {
    setMaxTerm: (state, action: PayloadAction<unknown>) => {
      state.maxTerm = Number(action.payload)
    },
  },
})

export default vehicleSlice.reducer

export const vehicleActions = vehicleSlice.actions

export const GET_VEHICLE_MAX_TERM = 'vehicle/maxTerm'

const vehicleSelectorsPartial = {
  getMaxTerm: (state: TRootState) => state.vehicle.maxTerm,
}

export const vehicleSelectors = {
  ...vehicleSelectorsPartial,
}
