import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Card, CardContent, CardMedia, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PageError, InputTextField } from '../../components'
import { useAppSelector, useSideEffect } from '../../data/store'
import { appSelectors } from '../../data/store/AppStore/app-store'
import { LoginCredentials, LoginCredentialsSchema } from '../../data/types/LoginCredentials'
import { userEffects } from '../../data/store/UserStore'
import LanguagePicker from '../App/components/LanguagePicker'
import ListMockedLoginDialog from './components/ListMockedLoginDialog'
import { useDevTools } from '../../data/types'

export default function LoginPage() {
  const error = useAppSelector(appSelectors.getBusinessError)
  const [isLoginMockDialogOpen, setIsLoginMockDialogOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginCredentials>({
    mode: 'onBlur',
    defaultValues: LoginCredentialsSchema.getDefault(),
    resolver: yupResolver(LoginCredentialsSchema),
  })

  const dispatchEffect = useSideEffect()

  const loginUser = (data: LoginCredentials) => {
    dispatchEffect(userEffects.login(data))
  }

  const onMockedLoginSelected = (username: string) => {
    const loginCreds = { username, password: 'ok' }
    loginUser(loginCreds)
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <ListMockedLoginDialog
        onMockedLoginSelected={onMockedLoginSelected}
        open={isLoginMockDialogOpen}
        onClose={() => setIsLoginMockDialogOpen(false)}
      />
      <Card>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LanguagePicker />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CardMedia sx={{ width: '200px' }} component="img" image="/images/iceberg-logo.png" alt="Iceberg Finance" />
        </div>
        <CardContent>
          <form onSubmit={handleSubmit<LoginCredentials>(loginUser)}>
            <Stack spacing={3} sx={{ paddingLeft: '100px', paddingRight: '100px', paddingTop: '20px' }}>
              <div style={{ width: 320, height: 45 }}>
                <PageError errors={error} />
              </div>
              <InputTextField {...register('username')} error={errors?.username} label={t('login.username')} />
              <InputTextField
                {...register('password')}
                error={errors?.password}
                label={t('login.password')}
                type="password"
              />
              <Button type="submit">{t('login.login')}</Button>
              {useDevTools && (
                <Box sx={{ display: 'flex', 'align-self': 'center', p: 2 }}>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsLoginMockDialogOpen(true)}
                  >
                    Cas de test de login
                  </Button>
                </Box>
              )}
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Box>
  )
}
