import React from 'react'
import { Grid } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Enum } from '../../../../../../../data/types/constants'
import AddressComponent from './AddressComponent'
import { ApplicantJob, JobSchema, SelectValueListItem } from '../../../../../../../data/types'
import { ActionsDialog, SelectComponent, InputTextField, InputTextFieldWithMask } from '../../../../../../../components'

type Props = {
  open: boolean
  title: string
  defaultValue: ApplicantJob
  onConfirm: (data: ApplicantJob) => void
  onCancel: () => void
}

function EditJobDialog({ defaultValue, onConfirm, onCancel, open, title }: Props) {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<ApplicantJob>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: defaultValue,
    resolver: yupResolver(JobSchema),
  })

  React.useEffect(() => {
    reset(defaultValue)
  }, [defaultValue])

  const onFormSubmitted = (data: ApplicantJob) => {
    onConfirm(data)
  }

  return (
    <ActionsDialog
      title={title}
      open={open}
      onCancel={onCancel}
      onConfirm={handleSubmit<ApplicantJob>(onFormSubmitted)}
    >
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={2}>
          <InputTextField
            error={errors?.annualSalary}
            label={t('editCreditApplication.income.job.annualSalary')}
            {...register('annualSalary')}
          />
        </Grid>
        <Grid item xs={12} md={2.5}>
          <SelectComponent
            content={Object.values(Enum.EJobType).map((item) => {
              const data: SelectValueListItem = { label: `enum.eJobType.${item}`, value: item }
              return data
            })}
            label={t('editCreditApplication.income.job.jobType')}
            {...register('jobType')}
            error={errors?.jobType}
          />
        </Grid>
        <Grid item xs={12} md={2.5}>
          <InputTextField
            error={errors?.jobTitle}
            label={t('editCreditApplication.income.job.jobTitle')}
            {...register('jobTitle')}
          />
        </Grid>
        <Grid item xs={12} md={2.5}>
          <InputTextField
            error={errors?.employerName}
            label={t('editCreditApplication.income.job.employerName')}
            {...register('employerName')}
          />
        </Grid>
        <Grid item xs={12} md={2.5}>
          <Controller
            name="employerPhone"
            control={control}
            render={({ field }) => (
              <InputTextFieldWithMask
                mask={Enum.EMask.phoneNumberMask}
                error={errors?.employerPhone}
                label={t('editCreditApplication.income.job.employerPhone')}
                {...field}
              />
            )}
          />
        </Grid>
        <AddressComponent
          formControl={control}
          register={register}
          errors={errors?.address}
          isCommercial
          name="address"
        />
      </Grid>
    </ActionsDialog>
  )
}

export default React.memo(EditJobDialog)
