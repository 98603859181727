import React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { store } from '../../../data/store'
import { appActions } from '../../../data/store/AppStore/app-store'

export default function App(): JSX.Element {
  const { t, i18n } = useTranslation()
  const targetLanguage = t('common.targetLanguage')

  const changeLanguage = () => {
    i18n.changeLanguage(targetLanguage)
    store.dispatch(appActions.setLanguage(targetLanguage))
  }

  return <Button onClick={changeLanguage}>{targetLanguage}</Button>
}
