import React from 'react'
import { userSelectors } from '../data/store/UserStore/user-store'
import { useAppSelector } from '../data/store'

type props = {
  requiredRole: string | null
  children: React.ReactNode
}

function SecurePage({ requiredRole = null, children }: props): JSX.Element {
  const user = useAppSelector(userSelectors.getUser)

  if (requiredRole && user?.roles?.indexOf(requiredRole) === -1)
    return <div>You are not authorized to view this page.</div>

  return children as JSX.Element
}
export default SecurePage
