import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { TRootState } from '..'
import type { CreditApplication } from '../../types'
import { apiStateSelectors } from '../ApiStateStore'

export const creditSlice = createSlice({
  name: 'credit',
  initialState: {
    current: null as CreditApplication | null,
    list: [] as CreditApplication[],
  },
  reducers: {
    setCurrent: (state, action: PayloadAction<CreditApplication | null>) => {
      state.current = action.payload
    },
    setList: (state, action: PayloadAction<CreditApplication[]>) => {
      state.list = action.payload
    },
  },
})

export default creditSlice.reducer

export const creditActions = creditSlice.actions

export const GET_CREDIT_BY_ID = 'credit/getById'
export const GET_CREDITS_BY_FILTERS = 'credit/getByFilters'
export const SAVE_CREDIT = 'credit/save'

const creditSelectorsPartial = {
  isLoadingCreditApplication: (state: TRootState) => apiStateSelectors.isLoading(state, GET_CREDIT_BY_ID),
  isSavingCreditApplication: (state: TRootState) => apiStateSelectors.isLoading(state, SAVE_CREDIT),
  isLoadingList: (state: TRootState) => apiStateSelectors.isLoading(state, GET_CREDITS_BY_FILTERS),

  getCurrent: (state: TRootState) => state.credit.current,
  getList: (state: TRootState) => state.credit.list,
}

export const creditSelectors = {
  ...creditSelectorsPartial,
}
