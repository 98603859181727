import { appInsights } from './AppInsights'

export function reportErrorToConsole(error: unknown) {
  // eslint-disable-next-line no-console
  console.log(error)
}
export function reportErrorToServer(error: Error) {
  reportErrorToConsole(error)
  appInsights.trackException({ error, severityLevel: 3 })
}
