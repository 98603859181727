import { IconButton, Stack, Typography, Paper, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Edit as EditIcon } from '@mui/icons-material'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import { FieldErrors } from 'react-hook-form'
import { Vehicle } from '../../../../../../data/types'
import { CopyMessage } from '../../../../../../services/strings'
import { formatCar, FormatCurrency, formatNumber } from '../../../../../../services/Formatter'

type Props = {
  onOpenTradeInVehiculeUpdateDialog: () => void
  vehicule: Vehicle | undefined | null
  errors: FieldErrors | undefined
}

export default function TradeInVehicleIdentification({ onOpenTradeInVehiculeUpdateDialog, errors, vehicule }: Props) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: 2,
        p: 2,
        mb: 3,
        border: errors !== undefined ? `2px solid ${theme.palette.error.main}` : '',
      }}
    >
      <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center">
          <Typography>
            {t('worksheet.vin')}: {vehicule?.vin}
          </Typography>
          <IconButton onClick={() => CopyMessage(vehicule?.vin ?? '')}>
            <CopyAllIcon />
          </IconButton>
        </Stack>
        <Typography>KM: {formatNumber(vehicule?.mileage)}</Typography>
        <Typography>
          {t('worksheet.allowance')}: {FormatCurrency(vehicule?.allowance)}
        </Typography>
        <IconButton onClick={() => onOpenTradeInVehiculeUpdateDialog()}>
          <EditIcon fontSize="large" color="primary" />
        </IconButton>
      </Stack>

      <Typography sx={{ mt: 1 }}>Model: {formatCar(vehicule)}</Typography>

      <Stack direction="row" spacing={3} marginTop={1} justifyContent="space-between" alignItems="center">
        <Typography>
          {t('worksheet.lienAmount')}: {FormatCurrency(vehicule?.lienAmount)}
        </Typography>
        <Typography>
          {t('worksheet.netTradeInAllowance')}: {FormatCurrency(vehicule?.netTradeInAllowance)}
        </Typography>
        <Typography>
          {t('worksheet.balanceOwedTo')}: {vehicule?.balanceOwedTo}
        </Typography>
      </Stack>
    </Paper>
  )
}
