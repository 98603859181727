import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Breadcrumb } from '../../components'
import { useSideEffect } from '../../data/store'
import { creditEffects } from '../../data/store/CreditApplication'
import { CreditApplication, FullCreditApplicationSchema } from '../../data/types'
import CreditApplicationForm from './components/CreditApplicationForm/CreditApplicationForm'
import { reportErrorToServer } from '../../services/error-logger'
import { decisionSlice } from '../../data/store/Decision/decision-store'

export default function CreateCreditApplicationPage(): JSX.Element {
  const { t } = useTranslation()
  const dispatchEffect = useSideEffect()
  const navigate = useNavigate()

  const [creditApplication] = useState<CreditApplication>(FullCreditApplicationSchema.getDefault() as CreditApplication)

  const onSave = (data: Partial<CreditApplication>) => {
    dispatchEffect(creditEffects.create(data))
      .then(() => {
        navigate('/credit-applications/browse', { replace: true })
      })
      .catch(reportErrorToServer)
    decisionSlice.actions.setCurrent(null)
    decisionSlice.actions.setHistoryList([])
  }

  const breadCrumbs = [
    { path: '/', label: t('breadcrumbs.home') },
    { path: '/credit-applications/browse', label: t('breadcrumbs.creditApplication') },
    { path: '#', label: t('breadcrumbs.newApplication') },
  ]

  return (
    <div>
      <Breadcrumb trees={breadCrumbs} />
      <CreditApplicationForm creditApplication={creditApplication} onSave={onSave} />
    </div>
  )
}
