import { Checkbox, FormControlLabel, Grid, Paper, Stack, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'
import { UseFormRegister, FieldErrors, Control, UseFormSetValue } from 'react-hook-form'
import EditVehicleDialog from './EditVehiculeDialog'
import VehiculeIdentification from './VehiculeIdentification'
import TradeInVehicleIdentification from './TradeInVehicleIdentification'
import {
  CreditApplication,
  Enum,
  SelectValueListItem,
  Vehicle,
  VehicleSchema,
  Worksheet,
} from '../../../../../../data/types'
import { DatePicker, InputTextField, SelectComponent } from '../../../../../../components'

type Props = {
  worksheet: Worksheet
  register: UseFormRegister<CreditApplication>
  errors: FieldErrors<Worksheet> | undefined
  setValue: UseFormSetValue<CreditApplication>
  formControl: Control<CreditApplication>
}

export default function WorksheetPage({ worksheet, register, errors, setValue, formControl }: Props) {
  const EditVehiculeDialogOpen = 'EditVehiculeDialogOpen'

  const [currentOpenDialog, setCurrentOpenDialog] = useState<string>('')
  const [isEditTradeInVehicule, setEditTradeInVehicule] = useState<boolean>(false)

  const theme = useTheme()

  const handleToggleHasTradeInVehicle = React.useCallback(() => {
    const updatedWs = { ...worksheet } as Worksheet
    if (updatedWs.tradeInVehicle !== null) updatedWs.tradeInVehicle = null
    else {
      updatedWs.tradeInVehicle = { ...VehicleSchema.getDefault(), isTradeIn: true }
    }
    setValue('autoWorksheet', updatedWs)
  }, [worksheet])

  const term = [...Array(13)].map((_, i) => 6 * (i + 1) + 6)

  const extendedServiceDuration: SelectValueListItem[] = [
    { value: 0, label: `12 ${t('worksheet.month')}` },
    { value: 1, label: `24 ${t('worksheet.month')}` },
    { value: 2, label: `36 ${t('worksheet.month')}` },
  ]
  const gapInsuranceDuration: SelectValueListItem[] = [
    { value: 0, label: `1 ${t('worksheet.to')} 59 ${t('worksheet.month')}` },
    { value: 1, label: `60 ${t('worksheet.month')} ${t('worksheet.andMore')}` },
  ]

  const onVehicleSelected = (data: Vehicle) => {
    if (isEditTradeInVehicule) {
      setValue('autoWorksheet.tradeInVehicle', data)
    } else {
      setValue('autoWorksheet.vehicle', data)
    }
    setCurrentOpenDialog('')
  }

  return (
    <div>
      <Stack>
        <VehiculeIdentification
          errors={errors?.vehicle}
          onOpenVehiculeUpdateDialog={() => {
            setEditTradeInVehicule(false)
            setCurrentOpenDialog(EditVehiculeDialogOpen)
          }}
          vehicule={worksheet?.vehicle}
        />
        <Grid>
          <FormControlLabel
            control={<Checkbox checked={!!worksheet.tradeInVehicle} onChange={handleToggleHasTradeInVehicle} />}
            label={t('worksheet.tradeIn')}
            sx={{ mb: 2 }}
          />
        </Grid>

        {worksheet.tradeInVehicle && (
          <TradeInVehicleIdentification
            errors={errors?.tradeInVehicle}
            onOpenTradeInVehiculeUpdateDialog={() => {
              setEditTradeInVehicule(true)
              setCurrentOpenDialog(EditVehiculeDialogOpen)
            }}
            vehicule={worksheet.tradeInVehicle}
          />
        )}

        <Paper
          elevation={3}
          sx={{
            borderColor: theme.palette.divider,
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 2,
            p: 1,
            mb: 3,
          }}
        >
          <Typography variant="h5" component="div" gutterBottom>
            Transaction
          </Typography>
          <Grid container item xs={12} spacing={2} alignContent="space-between" marginBottom={5}>
            <Grid item xs={12} md={3}>
              <InputTextField
                label={t('worksheet.price')}
                error={errors?.transaction?.price}
                {...register('autoWorksheet.transaction.price')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControlLabel
                {...register('autoWorksheet.transaction.isTaxExempt')}
                control={<Checkbox />}
                label={t('worksheet.taxExempt')}
                sx={{ m: -1 }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <InputTextField
                error={errors?.transaction?.rebate}
                {...register('autoWorksheet.transaction.rebate')}
                label={t('worksheet.rebate')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputTextField
                error={errors?.transaction?.cashDown}
                {...register('autoWorksheet.transaction.cashDown')}
                label={t('worksheet.cashDown')}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ flexDirection: 'row' }}>
              <DatePicker
                name="autoWorksheet.transaction.deliveryOn"
                control={formControl}
                label={t('worksheet.deliveryOn')}
                error={errors?.transaction?.deliveryOn}
                disablePast
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ flexDirection: 'row' }}>
              <DatePicker
                name="autoWorksheet.transaction.firstPaymentDate"
                control={formControl}
                label={t('worksheet.firstPayment')}
                error={errors?.transaction?.firstPaymentDate}
                disablePast
              />
            </Grid>
          </Grid>

          <Typography variant="h6" component="div" gutterBottom>
            {t('worksheet.feesAndAccessories')}
          </Typography>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={2.4}>
              <InputTextField
                error={errors?.transaction?.feesAndAccessories?.installationDelivery}
                {...register('autoWorksheet.transaction.feesAndAccessories.installationDelivery')}
                label={t('worksheet.installationAndDelivery')}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <InputTextField
                error={errors?.transaction?.feesAndAccessories?.licenseFee}
                {...register('autoWorksheet.transaction.feesAndAccessories.licenseFee')}
                label={t('worksheet.licenseFee')}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <InputTextField
                error={errors?.transaction?.feesAndAccessories?.dealerAdminFee}
                {...register('autoWorksheet.transaction.feesAndAccessories.dealerAdminFee')}
                label={t('worksheet.dealerAdminFee')}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <InputTextField
                error={errors?.transaction?.feesAndAccessories?.dealerAdminFee}
                {...register('autoWorksheet.transaction.feesAndAccessories.icebergLenderAdminFee')}
                disabled
                label={t('worksheet.lenderAdminFee')}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <InputTextField
                error={errors?.transaction?.feesAndAccessories?.licenseFee}
                {...register('autoWorksheet.transaction.feesAndAccessories.icebergPPSA')}
                disabled
                label={t('worksheet.ppsa')}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={3}>
              <InputTextField
                error={errors?.transaction?.feesAndAccessories?.otherTaxable}
                {...register('autoWorksheet.transaction.feesAndAccessories.otherTaxable')}
                label={t('worksheet.otherTaxable')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputTextField
                error={errors?.transaction?.feesAndAccessories?.otherTaxableDescription}
                {...register('autoWorksheet.transaction.feesAndAccessories.otherTaxableDescription')}
                label={t('worksheet.otherTaxableDescription')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputTextField
                error={errors?.transaction?.feesAndAccessories?.otherNonTaxable}
                {...register('autoWorksheet.transaction.feesAndAccessories.otherNonTaxable')}
                label={t('worksheet.otherNonTaxable')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputTextField
                error={errors?.transaction?.feesAndAccessories?.otherNonTaxableDescription}
                {...register('autoWorksheet.transaction.feesAndAccessories.otherNonTaxableDescription')}
                label={t('worksheet.otherNonTaxableDescription')}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" component="div" gutterBottom>
            {t('worksheet.aftermarketService')}
          </Typography>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={3}>
              <InputTextField
                error={errors?.transaction?.aftermarketService?.extendedService}
                {...register('autoWorksheet.transaction.aftermarketService.extendedService')}
                label={t('worksheet.extendedService')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <SelectComponent
                content={extendedServiceDuration}
                values={extendedServiceDuration}
                label={t('worksheet.extendedServiceDuration')}
                {...register('autoWorksheet.transaction.aftermarketService.extendedServiceDuration')}
                error={errors?.transaction?.aftermarketService?.extendedServiceDuration}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputTextField
                error={errors?.transaction?.aftermarketService?.gapInsurance}
                {...register('autoWorksheet.transaction.aftermarketService.gapInsurance')}
                label={t('worksheet.gapInsurance')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <SelectComponent
                content={gapInsuranceDuration}
                values={gapInsuranceDuration}
                error={errors?.transaction?.aftermarketService?.gapInsuranceDuration}
                {...register('autoWorksheet.transaction.aftermarketService.gapInsuranceDuration')}
                label={t('worksheet.gapInsuranceDuration')}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={3}>
              <InputTextField
                error={errors?.transaction?.aftermarketService?.lifeInsurance}
                {...register('autoWorksheet.transaction.aftermarketService.lifeInsurance')}
                label={t('worksheet.lifeInsurance')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputTextField
                error={errors?.transaction?.aftermarketService?.accidentAndHealthInsurance}
                {...register('autoWorksheet.transaction.aftermarketService.accidentAndHealthInsurance')}
                label={t('worksheet.accidentAndHealthInsurance')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputTextField
                error={errors?.transaction?.aftermarketService?.taxesOnInsurance}
                {...register('autoWorksheet.transaction.aftermarketService.taxesOnInsurance')}
                label={t('worksheet.taxesOnInsurance')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputTextField
                error={errors?.transaction?.aftermarketService?.aftermarketVsValue}
                {...register('autoWorksheet.transaction.aftermarketService.aftermarketVsValue')}
                label={t('worksheet.aftermarketVsValue')}
                disabled
              />
            </Grid>
          </Grid>

          <Typography variant="h6" component="div" gutterBottom>
            {t('worksheet.funding')}
          </Typography>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={4}>
              <SelectComponent
                content={term.map((item) => {
                  const data: SelectValueListItem = {
                    label: `${item} ${t('worksheet.month')}`,
                    value: item,
                  }
                  return data
                })}
                label={t('worksheet.term')}
                {...register('autoWorksheet.transaction.term')}
                error={errors?.transaction?.term}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectComponent
                content={Object.values(Enum.EPaymentFrequency).map((item) => {
                  const data: SelectValueListItem = { label: `worksheet.${item}`, value: item }
                  return data
                })}
                label={t('worksheet.paymentFrequency')}
                {...register('autoWorksheet.transaction.paymentFrequency')}
                error={errors?.transaction?.paymentFrequency}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputTextField
                error={errors?.transaction?.interestRate}
                label={t('worksheet.interestRate')}
                {...register('autoWorksheet.transaction.interestRate')}
              />
            </Grid>
          </Grid>
        </Paper>
      </Stack>

      <EditVehicleDialog
        open={currentOpenDialog === EditVehiculeDialogOpen}
        title={
          isEditTradeInVehicule ? t('worksheet.editTradeInVehicleDialogTitle') : t('worksheet.editVehicleDialogTitle')
        }
        onCancel={() => setCurrentOpenDialog('')}
        onConfirm={onVehicleSelected}
        isTradeIn={isEditTradeInVehicule}
        defaultValue={isEditTradeInVehicule ? (worksheet.tradeInVehicle as Vehicle) : worksheet.vehicle}
      />
    </div>
  )
}
