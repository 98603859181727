import { Decision } from '../../types/Decision'
import { makeGetById } from '../effects/standard-side-effects'
import { decisionSlice, GET_DECISION_BY_ID } from './decision-store'
import allApi from '../../api'

export const decisionEffects = {
  getById: (id: string) =>
    makeGetById<Decision>(GET_DECISION_BY_ID, allApi.decision.getById, decisionSlice.actions.setCurrent, {
      id,
    }),
}
