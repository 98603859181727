import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

interface Props {
  isLoading: boolean
  withBackdrop?: boolean
}

export default function PageSpinner({ isLoading, withBackdrop }: Props) {
  return (
    <Backdrop open={isLoading} invisible={!withBackdrop}>
      <CircularProgress />
    </Backdrop>
  )
}

PageSpinner.defaultProps = {
  withBackdrop: false,
}
