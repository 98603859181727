import React from 'react'
import { Grid, Typography, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CallIcon from '@mui/icons-material/Call'
import EmailIcon from '@mui/icons-material/Email'
import { CreditApplication, Merchant } from '../../../data/types'

type Props = {
  currentApplication: CreditApplication
  merchantsByIds: Record<number, Merchant>
}
function MessageHeader({ currentApplication, merchantsByIds }: Props) {
  const merchant = merchantsByIds[currentApplication.merchantId]
  const { t } = useTranslation()

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" textAlign="center">
          {merchant.province}
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Link variant="body1" href={`/credit-applications/${currentApplication.id}/view`}>
          {t('common.application')} : #{currentApplication.dtReferenceId}
        </Link>
        <Typography variant="body1" component="div">
          {currentApplication.applicant.firstName} {currentApplication.applicant.middleName}{' '}
          {currentApplication.applicant.lastName} {' - '} {currentApplication.coApplicant?.firstName}{' '}
          {currentApplication.coApplicant?.middleName} {currentApplication.coApplicant?.lastName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="div" textAlign="center">
          {merchant.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="div" textAlign="center">
          <CallIcon /> {merchant.phone}
        </Typography>
      </Grid>
      {merchant.email && (
        <Grid item xs={12}>
          <Typography variant="body1" component="div" textAlign="center">
            <EmailIcon />
            <a href={`mailto:${merchant.email}`}>{merchant.email}</a>
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
export default MessageHeader
