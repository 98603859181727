import React, { ReactElement } from 'react'
import { Fab, Tooltip } from '@mui/material'

type Props = {
  tooltip: string
  color: 'inherit' | 'primary' | 'secondary' | 'default' | 'success' | 'error' | 'info' | 'warning' | undefined
  size: 'small' | 'medium' | 'large' | undefined
  image: ReactElement
  action: () => void | undefined
}

export default function ImageActionButton(props: Props): JSX.Element {
  return (
    <Tooltip title={props.tooltip} arrow placement="top">
      <Fab onClick={props.action} size={props.size} sx={{ m: 1 }} color={props.color}>
        {props.image}
      </Fab>
    </Tooltip>
  )
}
