import React from 'react'
import { Routes, Route } from 'react-router-dom'

import BrowseCreditApplicationsPage from '../BrowseCreditApplicationsPage'
import EditCreditApplicationPage from '../EditCreditApplicationPage/EditCreditApplicationPage'
import CreateCreditApplicationPage from '../EditCreditApplicationPage/CreateCreditApplicationPage'
import LoginPage from '../LoginPage/LoginPage'
import NotePage from '../NotePage'

export default function TopLevelRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<BrowseCreditApplicationsPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="credit-applications/browse" element={<BrowseCreditApplicationsPage />} />
      <Route path="credit-applications/add" element={<CreateCreditApplicationPage />} />
      <Route path="credit-applications/:id/edit" element={<EditCreditApplicationPage />} />
      <Route path="credit-applications/:id/notes" element={<NotePage />} />
      <Route path="*" element={<>Not Found</>} />
    </Routes>
  )
}
