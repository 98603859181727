import { omit } from 'lodash'
import type { Applicant, ApplicantAddress, ApplicantJob, CreditApplication } from '../../types'
import { ApplicantAddressDto, ApplicantDto, ApplicantJobDto, CreditApplicationDto } from './CreditApplicationDto'

// To API
function transformApplicantAddressToApi(address: ApplicantAddress): ApplicantAddressDto {
  const ret = omit(address, 'years') as ApplicantAddressDto
  ret.months = (address.years ?? 0) * 12 + (address.months ?? 0)
  return ret
}

function transformApplicantJobToApi(job: ApplicantJob): ApplicantJobDto {
  const ret = omit(job, 'years') as ApplicantJobDto
  ret.months = (job.years ?? 0) * 12 + (job.months ?? 0)
  return ret
}

function transformApplicantToApi(applicant: Applicant): ApplicantDto {
  const ret: ApplicantDto = { ...applicant } as ApplicantDto

  if (applicant.currentAddress) ret.currentAddress = transformApplicantAddressToApi(applicant.currentAddress)

  ret.previousAddresses = []
  if (applicant.previousAddresses) {
    ret.previousAddresses = applicant.previousAddresses.map(transformApplicantAddressToApi)
  }

  ret.currentJobs = []
  if (applicant.currentJobs) {
    ret.currentJobs = applicant.currentJobs.map(transformApplicantJobToApi)
  }

  ret.previousJobs = []
  if (applicant.previousJobs) {
    ret.previousJobs = applicant.previousJobs.map(transformApplicantJobToApi)
  }

  if (applicant.homeFees) {
    applicant.homeFees.monthlyPayment = applicant.homeFees.monthlyPayment ? applicant.homeFees.monthlyPayment : null
    applicant.homeFees.mortgageAmount = applicant.homeFees.mortgageAmount ? applicant.homeFees.mortgageAmount : null
    applicant.homeFees.houseMarketValue = applicant.homeFees.houseMarketValue
      ? applicant.homeFees.houseMarketValue
      : null
  }

  return ret
}

export function transformCreditApplicationToApi(data: CreditApplication): CreditApplicationDto {
  const ret = { ...data } as CreditApplicationDto
  ret.applicant = transformApplicantToApi(data.applicant)
  if (data.coApplicant) ret.coApplicant = transformApplicantToApi(data.coApplicant)

  return ret
}

// From API
function transformApplicantAddressFromApi(address: ApplicantAddressDto): ApplicantAddress {
  const ret = { ...address } as ApplicantAddress
  ret.months = address.months % 12
  ret.years = (address.months - ret.months) / 12
  return ret
}

function transformApplicantJobFromApi(job: ApplicantJobDto): ApplicantJob {
  const ret = { ...job } as ApplicantJob
  ret.months = job.months % 12
  ret.years = (job.months - ret.months) / 12
  return ret
}

function transformApplicantFromApi(applicant: ApplicantDto): Applicant {
  const ret = { ...applicant } as Applicant
  if (applicant.currentAddress) ret.currentAddress = transformApplicantAddressFromApi(applicant.currentAddress)

  if (applicant.previousAddresses) {
    ret.previousAddresses = applicant.previousAddresses.map(transformApplicantAddressFromApi)
  }

  if (applicant.currentJobs) {
    ret.currentJobs = applicant.currentJobs.map(transformApplicantJobFromApi)
  }

  if (applicant.previousJobs) {
    ret.previousJobs = applicant.previousJobs.map(transformApplicantJobFromApi)
  }

  return ret
}

export function transformCreditApplicationFromApi(application: CreditApplicationDto): CreditApplication {
  const ret = { ...application } as CreditApplication
  ret.applicant = transformApplicantFromApi(application.applicant)
  if (application.coApplicant) ret.coApplicant = transformApplicantFromApi(application.coApplicant)
  return ret
}
