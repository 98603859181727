import * as yup from './common/yup-extended'
import yupExtInt from './common/SchemaTypes'

export const baseAddressSchema = {
  civicNumber: yup.default.string().nullable().default(''),
  street: yup.default.string().nullable().default(''),
  apartment: yup.default.string().nullable().nullable(),
  suite: yup.default.string().nullable().nullable(),
  city: yup.default.string().nullable().default(''),
  postalCode: yup.default.string().nullable().default('').IsValidCanadianPostalCode(),
  country: yup.default.string().nullable().default('Canada'),
  stateIso: yup.default.string().nullable().default(''),
  note: yup.default.string().nullable().default(''),
  years: yupExtInt.integer,
}

export const AddressSchema = yup.default.object(baseAddressSchema).when((value, schema) => {
  if (value?.civicNumber || value?.apartment || value?.suite || value?.street || value?.city || value?.postalCode) {
    schema.fields.civicNumber = schema.fields.civicNumber.required()
    schema.fields.street = schema.fields.street.required()
    schema.fields.city = schema.fields.city.required()
    schema.fields.postalCode = schema.fields.postalCode.required()
    schema.fields.stateIso = schema.fields.stateIso.required()
  }
  return schema
})

export type Address = yup.default.InferType<typeof AddressSchema>
