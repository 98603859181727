import React from 'react'
import { Box, Typography } from '@mui/material'
import { Note } from '../../../data/types'
import { formatDateTime } from '../../../services/Formatter'

type Props = {
  note: Note
}
function NoteView({ note }: Props) {
  const boxColor = () => {
    if (note.isFromIceberg) return '#039be5' // Message from iceberg user lightblue
    return '#aed581' // Message from merchant ligthgreen
  }
  return (
    <div>
      <Box textAlign={note.isFromIceberg ? 'right' : 'left'} sx={{ mb: 1, fontSize: 10 }}>
        {note.createdByUserFullname} {formatDateTime(note.createdOn)}
      </Box>
      <Box
        sx={{
          width: 'fit-content',
          backgroundColor: boxColor,
          borderRadius: 2,
          p: 1,
          mb: 2,
        }}
      >
        <Typography maxWidth="50vw" color="white" whiteSpace="pre-wrap" sx={{ fontSize: 18 }}>
          {note.content}
        </Typography>
      </Box>
    </div>
  )
}
export default NoteView
