import React from 'react'
import { Box, Divider, Grid, Stack, Typography, useTheme, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Decision } from '../../../../../../data/types/Decision'
import { FormatCurrency, formatDateTime } from '../../../../../../services/Formatter'

type Props = {
  decision: Decision
}

export default function DecisionTab({ decision }: Props) {
  const { t } = useTranslation()
  const theme = useTheme()
  const borderColor = theme.palette.divider
  const backgroundColor = theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black

  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mb: 3 }}>
      <Divider>
        <Typography variant="h4" component="span" gutterBottom>
          {t('decision.label')}
        </Typography>
      </Divider>
      <Grid item xs={12} md={12} lg={6}>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
          sx={{ color: backgroundColor }}
        >
          <Stack spacing={1}>
            <Typography variant="body1" component="div">
              {t('worksheet.interestRate')}: {decision.icebergDecision.buyRate}%
            </Typography>
            <Typography variant="body1" component="div">
              {t('decision.decision')}: {t(`enum.eDecisionStatus.${decision.icebergDecision.decStatus}`)}
            </Typography>
            <Typography variant="body1" component="div">
              {t('decision.decisionTime')}: {formatDateTime(decision.icebergDecision.decDateTime)}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body1" component="div">
              {t('worksheet.funding')}: {FormatCurrency(decision.icebergDecision.approvedAmount)}
            </Typography>
            <Typography variant="body1" component="div">
              {t('worksheet.paymentAmount')}: {FormatCurrency(decision.icebergDecision.payment)}
            </Typography>
            <Typography variant="body1" component="div">
              {t('worksheet.term')}: {decision.icebergDecision.term} {t('worksheet.month')}
            </Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            height: 20,
          }}
        />

        <Box
          sx={{
            border: '1px solid',
            borderColor,
            borderRadius: 2,
            p: 1,
          }}
        >
          <Typography variant="body1" component="div">
            {t('decision.label')}:
          </Typography>
          {decision.icebergDecision.conditions.map((x) => {
            return (
              <Typography key={x.conSequenceNumber} variant="body1" component="h6" sx={{ marginTop: 1 }}>
                {x.conSequenceNumber}: {x.conDescription}
              </Typography>
            )
          })}
        </Box>
      </Grid>
    </Paper>
  )
}
