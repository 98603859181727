import { AxiosInstance, AxiosRequestConfig, AxiosRequestTransformer } from 'axios'
import { isArray, isObject, get } from 'lodash'
import download from 'downloadjs'

const applyTransform = (data: unknown, transform: AxiosRequestTransformer | undefined) => {
  if (!transform) return data

  if (isArray(data)) {
    return data.map((x) => transform(x))
  }
  if (data) {
    return transform(data)
  }

  return data
}

export interface GetSingleFromApi<T extends object> {
  (apiClient: AxiosInstance, ids: Record<string, string | number>): Promise<T>
}

export interface GetListFromApi<T extends object> {
  (apiClient: AxiosInstance, filters?: Record<string, string | number | string[] | number[]>): Promise<T[]>
}

export interface GetListWithIdsFromApi<T extends object> {
  (
    apiClient: AxiosInstance,
    ids: Record<string, string>,
    filters?: Record<string, string | number | string[] | number[]>,
  ): Promise<T[]>
}

export interface SaveToApi<TInput extends object, TOutput extends object = TInput> {
  (apiClient: AxiosInstance, data: Partial<TInput>): Promise<TOutput>
}

export interface DeleteToApi<TInput extends object> {
  (apiClient: AxiosInstance, data: Partial<TInput>): Promise<void>
}

export interface DownloadFromApi {
  (fileName: string): Promise<void>
}

export interface DownloadWithObjectFromApi<TInput extends object> {
  (apiClient: AxiosInstance, data: Partial<TInput>): Promise<void>
}

const serializeParameter = (params: Record<string, string | number | string[] | number[]> | undefined) => {
  const searchParams = new URLSearchParams()
  if (params)
    Object.keys(params).forEach((key) => {
      const v = params[key]
      if (v.toString()) searchParams.append(key, v.toString())
    })
  return searchParams.toString()
}

export function buildUrlWithIds(baseUrl: string, ids: Record<string, unknown>): string {
  let newUrl = baseUrl
  const paramNames = baseUrl.match(/[^{}]+(?=})/g)
  paramNames?.forEach((name) => {
    const value = get(ids, name)
    if (value === undefined || value === null || value === '') {
      throw new Error(`parameter ${name} for url ${baseUrl} was given a missing value.`)
    } else if (isObject(ids[name])) {
      throw new Error(`parameter ${name} for url ${baseUrl} was given an object instead of a direct value.`)
    } else {
      newUrl = newUrl.replace(`{${name}}`, (value as object).toString())
    }
  })

  return newUrl
}

export const makeGetSingleFromApi = <T extends object>(
  baseUrl: string,
  fromApi?: AxiosRequestTransformer | undefined,
  hostname?: string,
): GetSingleFromApi<T> => {
  return async (apiClient: AxiosInstance, ids: Record<string, string | number>): Promise<T> => {
    const payload: AxiosRequestConfig<T> = { method: 'GET', url: buildUrlWithIds(baseUrl, ids) }

    if (hostname) payload.baseURL = hostname

    const response = await apiClient.request(payload)
    const ret = applyTransform(response.data, fromApi)
    return ret as T
  }
}

export const makeGetListFromApi = <T extends object>(
  baseUrl: string,
  fromApi?: AxiosRequestTransformer | undefined,
): GetListFromApi<T> => {
  return async (
    apiClient: AxiosInstance,
    filters?: Record<string, string | number | string[] | number[]>,
  ): Promise<T[]> => {
    const payload: AxiosRequestConfig<T> = { method: 'GET', url: baseUrl.concat(`?${serializeParameter(filters)}`) }
    const response = await apiClient.request(payload)
    const ret = applyTransform(response.data, fromApi)
    return ret as T[]
  }
}

export const makeGetListWithIdsFromApi = <T extends object>(
  baseUrl: string,
  fromApi?: AxiosRequestTransformer | undefined,
): GetListWithIdsFromApi<T> => {
  return async (
    apiClient: AxiosInstance,
    ids: Record<string, string>,
    filters?: Record<string, string | number | string[] | number[]>,
  ): Promise<T[]> => {
    const payload: AxiosRequestConfig<T> = {
      method: 'GET',
      url: buildUrlWithIds(baseUrl, ids).concat(`?${serializeParameter(filters)}`),
    }
    const response = await apiClient.request(payload)
    const ret = applyTransform(response.data, fromApi)
    return ret as T[]
  }
}

export const makeCreate = <TInput extends object, TOutput extends object = TInput>(
  baseUrl: string,
  toApi?: AxiosRequestTransformer | undefined,
  fromApi?: AxiosRequestTransformer | undefined,
): SaveToApi<TInput, TOutput> => {
  return async (apiClient: AxiosInstance, data: Partial<TInput>): Promise<TOutput> => {
    const payload: AxiosRequestConfig<TInput[]> = {
      method: 'POST',
      url: buildUrlWithIds(baseUrl, data),
      data: applyTransform(data, toApi),
    }
    const response = await apiClient.request(payload)
    const ret = applyTransform(response.data, fromApi)
    return ret as TOutput
  }
}

export const makeUpdate = <TInput extends object, TOutput extends object = TInput>(
  baseUrl: string,
  toApi?: AxiosRequestTransformer | undefined,
  fromApi?: AxiosRequestTransformer | undefined,
): SaveToApi<TInput, TOutput> => {
  return async (apiClient: AxiosInstance, data: Partial<TInput>): Promise<TOutput> => {
    const payload: AxiosRequestConfig<TInput> = {
      url: buildUrlWithIds(baseUrl, data),
      method: 'PUT',
      data: applyTransform(data, toApi),
    }
    const response = await apiClient.request(payload)
    const ret = applyTransform(response.data, fromApi)
    return ret as TOutput
  }
}

export const makeDelete = <TInput extends object>(
  baseUrl: string,
  toApi?: AxiosRequestTransformer | undefined,
): DeleteToApi<TInput> => {
  return async (apiClient: AxiosInstance, data: Partial<TInput>): Promise<void> => {
    const payload: AxiosRequestConfig<TInput> = {
      method: 'DELETE',
      url: buildUrlWithIds(baseUrl, data),
      data: applyTransform(data, toApi),
    }
    await apiClient.request(payload)
    return Promise.resolve()
  }
}

export const makeDownload = (url: string): DownloadFromApi => {
  return (fileName: string) => {
    const downloadLink = document.createElement('a')
    downloadLink.download = fileName
    downloadLink.href = url
    downloadLink.click()
    return Promise.resolve()
  }
}

export const makeDownloadWithObject = <TInput extends object>(url: string): DownloadWithObjectFromApi<TInput> => {
  return async (apiClient: AxiosInstance, data: Partial<TInput>) => {
    const payload: AxiosRequestConfig = {
      method: 'POST',
      responseType: 'blob',
      url,
      data,
    }
    await apiClient.request(payload).then((response) => {
      const filenameHeader = response.headers['content-disposition']?.split(';').find((n) => n.includes('filename='))
      if (filenameHeader) {
        const filename = filenameHeader.replace('filename=', '').trim()
        download(response.data, filename)
      }
    })
    return Promise.resolve()
  }
}

export const getActionName = (apiName: string, actionName: string) => `${apiName}/${actionName}`
export const createEntityApi = <T extends object>(
  baseUrl: string,
  fromApi?: AxiosRequestTransformer,
  toApi?: AxiosRequestTransformer,
) => {
  const url = baseUrl.endsWith('/') ? baseUrl.substring(0, baseUrl.length - 1) : baseUrl
  const urlWithId = `${url}/{id}`
  return {
    getById: makeGetSingleFromApi<T>(urlWithId, fromApi),
    create: makeCreate<T>(url, toApi, fromApi),
    update: makeUpdate<T>(urlWithId, toApi, fromApi),
    delete: makeDelete<T>(urlWithId, toApi),
  }
}
