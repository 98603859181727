import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { useCallback } from 'react'
import { TRootState, useAppDispatch, useAppSelector } from '..'
import { appSelectors, appActions } from '../AppStore/app-store'
import { userActions } from '../UserStore/user-store'
import { appInsights } from '../../../services/AppInsights'
import type { TValidationError, AsyncEffectHandler } from './side-effects-types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function standardApiErrorHandling(ex: any, dispatch: ThunkDispatch<TRootState, undefined, AnyAction>) {
  const error: AxiosError<TValidationError> = ex // cast the error for access
  const errorDetail = error?.response?.data as TValidationError

  if (!error.response) {
    dispatch(appActions.setTechnicalError(error.toString()))
    appInsights.trackException({ error, severityLevel: 3 })
  } else if (error.response.status === 401) {
    dispatch(userActions.setMustAuthenticate(true))
  } else if (error.response.status === 400 && errorDetail?.message) {
    if (errorDetail.type === 'FieldValidations')
      appInsights.trackException({ error: new Error(error.request), severityLevel: 3 })
    dispatch(appActions.setBusinessErrors([{ message: errorDetail.message }]))
  }
  // todo: handle conflict here
  else if (error.response.status !== 404) {
    dispatch(appActions.setTechnicalError(error.response.data.toString()))
  }
}

/**
 * Creates a 'dispatch' function used to invoke api (created with the function 'createApi')
 */
export const useSideEffect = () => {
  const dispatch = useAppDispatch()
  const apiClient = useAppSelector(appSelectors.getApiClient)
  const callback = useCallback(
    <T>(apiCall: AsyncEffectHandler<T>) => {
      return apiCall(apiClient, dispatch, useAppSelector).catch((ex) => {
        standardApiErrorHandling(ex, dispatch)
        return Promise.reject(ex)
      })
    },
    [apiClient, dispatch],
  )
  return callback
}
