import { createEntityApi, makeGetListFromApi } from '../make-api'
import { transformCreditApplicationFromApi, transformCreditApplicationToApi } from './credit-transform'
import { CreditApplication } from '../../types'

const baseUrl = '/AutoCreditApplication'
const creditApplicationApi = {
  ...createEntityApi<CreditApplication>(baseUrl, transformCreditApplicationFromApi, transformCreditApplicationToApi),
  getByFilters: makeGetListFromApi<CreditApplication>(baseUrl, transformCreditApplicationFromApi),
}

export default creditApplicationApi
