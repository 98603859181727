/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { Control, FieldError, useController } from 'react-hook-form'
import { format } from 'date-fns'
import { DatePicker, DateTimePicker as MuiDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

function makeDatePickerChangeHandler(onChange: (...event: any[]) => void, name: string, dateTimeFormat = true) {
  return (newDate: Date | null): Promise<void> => {
    const triggerEvent = {
      target: {
        name,
        value: '',
      },
    }

    if (newDate) {
      try {
        triggerEvent.target.value = format(newDate, dateTimeFormat ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')
      } catch (e) {
        // means the date in invalid, put the string directly so that the validation might show an error
        triggerEvent.target.value = newDate.toString()
      }
    }

    onChange(triggerEvent)
    return Promise.resolve()
  }
}

type DateTimePickerProps = {
  error: FieldError | undefined
  name: string
  label: string
  control: Control<any>
  disableFuture?: boolean
  required?: boolean
  disablePast?: boolean
}

export function DateTimePicker({ name, control, label, disableFuture, required, disablePast }: DateTimePickerProps) {
  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error },
  } = useController({ name, control })

  const changeHandler = useCallback(makeDatePickerChangeHandler(onChange, name), [name, onChange])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDateTimePicker
        views={['year', 'day', 'hours']}
        label={label}
        value={value}
        onChange={changeHandler}
        disableFuture={disableFuture}
        disablePast={disablePast}
        inputFormat="yyyy-MM-dd HH:mm"
        mask="____-__-__ __:__"
        reduceAnimations
        ampm={false}
        renderInput={(params: TextFieldProps) => (
          <TextField
            ref={ref}
            {...params}
            value={value}
            onBlur={onBlur}
            error={error !== undefined}
            required={required}
            fullWidth
          />
        )}
      />
    </LocalizationProvider>
  )
}

DateTimePicker.defaultProps = {
  required: false,
  disablePast: false,
  disableFuture: false,
}

type DatePickerProps = {
  error: FieldError | undefined
  name: string
  label: string
  control: Control<any>
  disableFuture?: boolean
  required?: boolean
  disablePast?: boolean
}

export function DatePickerInput({
  name,
  control,
  label,
  disableFuture,
  error,
  disablePast,
  ...props
}: DatePickerProps) {
  const {
    field: { onChange, onBlur, ref, value },
  } = useController({ name, control })

  const changeHandler = useCallback(makeDatePickerChangeHandler(onChange, name, false), [name, onChange])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        mask="____-__-__"
        inputFormat="yyyy-MM-dd"
        reduceAnimations
        disableFuture={disableFuture}
        disablePast={disablePast}
        onChange={changeHandler}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            onBlur={onBlur}
            error={Boolean(error)}
            helperText={error?.message}
            value={value}
            fullWidth
          />
        )}
        {...props}
        value={value && (value as string).includes('T') ? value : `${value}T00:00:00`}
        ref={ref}
      />
    </LocalizationProvider>
  )
}

DatePickerInput.defaultProps = {
  required: false,
  disablePast: false,
  disableFuture: false,
}
export { DatePickerInput as DatePicker }
export default DateTimePicker
