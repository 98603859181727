import React from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Control, Controller, FieldErrors, UseFormRegister } from 'react-hook-form'
import { Applicant, CreditApplication, SelectValueListItem } from '../../../../../../../data/types'
import { Enum } from '../../../../../../../data/types/constants'
import { InputTextFieldWithMask, InputTextField, SelectComponent, DatePicker } from '../../../../../../../components'

type Props = {
  register: UseFormRegister<CreditApplication>
  errors: FieldErrors<Applicant> | undefined
  name: 'coApplicant' | 'applicant'
  formControl: Control<CreditApplication>
}

function ApplicantInformationParameters({ register, errors, name, formControl }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <input type="hidden" {...register(`${name}.id`)} />
      <Divider style={{ fontSize: 25 }}>
        <Typography variant="h4" component="span" gutterBottom>
          {t('editCreditApplication.information.label')}
        </Typography>
      </Divider>

      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.firstName}
            label={t('editCreditApplication.information.firstName')}
            {...register(`${name}.firstName`)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.middleName}
            label={t('editCreditApplication.information.middleName')}
            {...register(`${name}.middleName`)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.lastName}
            label={t('editCreditApplication.information.lastName')}
            {...register(`${name}.lastName`)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePicker
            name={`${name}.birthDate`}
            control={formControl}
            label={t('editCreditApplication.information.birthDate')}
            error={errors?.birthDate}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`${name}.sin`}
            control={formControl}
            render={({ field }) => (
              <InputTextFieldWithMask
                mask={Enum.EMask.sinMask}
                error={errors?.sin}
                label={t('editCreditApplication.information.sin')}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            content={Object.values(Enum.EGender).map((item) => {
              const data: SelectValueListItem = { label: `enum.eGender.${item}`, value: item }
              return data
            })}
            label={t('editCreditApplication.information.genderId')}
            {...register(`${name}.genderId`)}
            error={errors?.genderId}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            content={Object.values(Enum.EMaritalStatus).map((item) => {
              const data: SelectValueListItem = { label: `enum.eMaritalStatus.${item}`, value: item }
              return data
            })}
            label={t('editCreditApplication.information.maritalStatusId')}
            {...register(`${name}.maritalStatusId`)}
            error={errors?.maritalStatusId}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            content={Object.values(Enum.ELanguage).map((item) => {
              const data: SelectValueListItem = { label: `enum.eLanguage.${item}`, value: item }
              return data
            })}
            label={t('editCreditApplication.information.languageId')}
            {...register(`${name}.languageId`)}
            error={errors?.languageId}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`${name}.homePhone`}
            control={formControl}
            render={({ field }) => (
              <InputTextFieldWithMask
                mask={Enum.EMask.phoneNumberMask}
                error={errors?.homePhone}
                label={t('editCreditApplication.information.homePhone')}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`${name}.cellPhone`}
            control={formControl}
            render={({ field }) => (
              <InputTextFieldWithMask
                mask={Enum.EMask.phoneNumberMask}
                error={errors?.cellPhone}
                label={t('editCreditApplication.information.cellPhone')}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.email}
            label={t('editCreditApplication.information.email')}
            {...register(`${name}.email`)}
          />
        </Grid>
        {name !== 'applicant' && (
          <Grid item xs={12} md={3}>
            {' '}
            <InputTextField
              error={errors?.relationWithApplicant}
              label={t('editCreditApplication.information.relationWithApplicant')}
              {...register(`${name}.relationWithApplicant`)}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}
export default ApplicantInformationParameters
