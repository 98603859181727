import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { TRootState } from '..'
import type { Note } from '../../types'
import { apiStateSelectors } from '../ApiStateStore'

export const noteSlice = createSlice({
  name: 'note',
  initialState: {
    noteList: [] as Note[],
  },
  reducers: {
    setNoteList: (state, action: PayloadAction<Note[]>) => {
      state.noteList = action.payload
    },
    addNote: (state, action: PayloadAction<Note>) => {
      state.noteList.push(action.payload)
    },
  },
})

export default noteSlice.reducer

export const noteActions = noteSlice.actions

export const GET_NOTE = 'note/getNoteList'
export const SAVE_NOTE = 'note/save'

const noteSelectorsPartial = {
  isLoadingNotegeList: (state: TRootState) => apiStateSelectors.isLoading(state, GET_NOTE),
  isSavingNote: (state: TRootState) => apiStateSelectors.isLoading(state, SAVE_NOTE),
  getNoteList: (state: TRootState) => state.note.noteList,
}

export const noteSelectors = {
  ...noteSelectorsPartial,
}
