import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { Add, Cached } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import ImageActionButton from '../../components/ImageActionButton'

export default function MainActions(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleNewApplication = () => {
    navigate('/credit-applications/add')
  }

  return (
    <Box>
      <ImageActionButton
        tooltip={t('browseCreditApplications.mainActions.newApplication')}
        color="primary"
        size="large"
        image={<Add />}
        action={handleNewApplication}
      />
      <ImageActionButton
        tooltip={t('browseCreditApplications.mainActions.refresh')}
        color="primary"
        size="large"
        image={<Cached />}
        action={() => undefined}
      />
    </Box>
  )
}
