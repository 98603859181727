import { Note } from '../../types/Note'
import { makeCreate, makeGetListWithIdsFromApi } from '../make-api'

const NOTE_BASE_URL = '/AutoCreditApplication/{dtReferenceId}/Notes'
const noteApi = {
  create: makeCreate<Note>(NOTE_BASE_URL),
  getNoteList: makeGetListWithIdsFromApi<Note>(NOTE_BASE_URL),
}

export default noteApi
