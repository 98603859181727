import type { CreditApplication } from '../../types'
import allApi from '../../api'
import { makeGetById, makeGetList, makeSave } from '../effects/standard-side-effects'
import { GET_CREDIT_BY_ID, creditSlice, SAVE_CREDIT, GET_CREDITS_BY_FILTERS } from './credit-application-store'

export const creditEffects = {
  getById: (id: string) =>
    makeGetById<CreditApplication>(GET_CREDIT_BY_ID, allApi.credit.getById, creditSlice.actions.setCurrent, {
      id,
    }),
  create: (data: Partial<CreditApplication>) =>
    makeSave<CreditApplication>(SAVE_CREDIT, allApi.credit.create, creditSlice.actions.setCurrent, data),
  update: (data: Partial<CreditApplication>) =>
    makeSave<CreditApplication>(SAVE_CREDIT, allApi.credit.update, creditSlice.actions.setCurrent, data),
  getByFilters: (filters?: Record<string, string | number>) =>
    makeGetList<CreditApplication>(
      GET_CREDITS_BY_FILTERS,
      allApi.credit.getByFilters,
      creditSlice.actions.setList,
      filters,
    ),
}
