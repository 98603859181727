import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

const rp = new ReactPlugin()
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHT_INSTRUMENTAL_KEY,
    extensions: [rp],
  },
})
ai.loadAppInsights()

export const appInsights: ApplicationInsights = ai
export const reactPlugin = rp
