import React, { useCallback, useState } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Paper, Tabs, Tab, Box, FormControlLabel, Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { useAppSelector, TRootState } from '../../../../data/store'
import { creditSelectors } from '../../../../data/store/CreditApplication'
import { appSelectors } from '../../../../data/store/AppStore'
import {
  Applicant,
  CreditApplication,
  FullApplicantSchema,
  FullCreditApplicationSchema,
  useDevTools,
  SelectValueListItem,
  worksheetSchema,
  Worksheet,
} from '../../../../data/types'
import { PageError, PageSpinner, SelectComponent, TabPanel } from '../../../../components'
import ApplicantParameters from './components/Applicant/applicantComponent'
import WorksheetTab from './components/Worksheet/WorksheetTab'
import SaveDialog from './components/SaveDialog'
import TestCaseDialog from './ListTestCaseDialog'
import { Enum } from '../../../../data/types/constants'

import { reportErrorToConsole } from '../../../../services/error-logger'
import { decisionSelectors } from '../../../../data/store/Decision/decision-store'
import DecisionTab from './components/Decision/DecisionTab'

interface Props {
  creditApplication: CreditApplication
  onSave: (data: Partial<CreditApplication>) => void
}

function CreditApplicationForm({ creditApplication, onSave }: Props) {
  const SAVE_DIALOG = 'OpenSaveDialog'
  const TEST_DIALOG = 'OpenTestDialog'

  const [searchParams] = useSearchParams()
  const showTabIndex = searchParams.get('showTabIndex')
  const [hasCoapplicantChecked, setCoApplicantChecked] = React.useState<boolean>(!!creditApplication.coApplicant)
  const tabIndex = Number(showTabIndex) ?? 0
  const [currentOpenDialog, setCurrentOpenDialog] = useState<string>('')
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm<CreditApplication>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: creditApplication,
    resolver: yupResolver(FullCreditApplicationSchema),
  })

  const isSaving = useAppSelector(creditSelectors.isSavingCreditApplication)
  const error = useAppSelector(appSelectors.getBusinessError)
  const merchants = useAppSelector((state: TRootState) => appSelectors.getMerchantForProgram(state, ''))
  const worksheet = watch('autoWorksheet') as Worksheet
  const decision = useAppSelector(decisionSelectors.getCurrent)

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    const { location } = window
    const updateSearch = new URLSearchParams(location.search)
    updateSearch.set('showTabIndex', encodeURIComponent(newValue))
    const newUrl = `${location.pathname}?${updateSearch.toString()}`
    navigate(newUrl, { replace: true })
  }, [])

  const closeDialogs = useCallback(() => {
    setCurrentOpenDialog('')
  }, [])

  const handleToggleCoapplicant = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue('coApplicant', FullApplicantSchema.getDefault() as Applicant)
    } else {
      setValue('coApplicant', null)
    }
    setCoApplicantChecked(event.target.checked)
  }, [])

  const handleToggleWorksheet = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue('autoWorksheet', worksheetSchema.getDefault() as Worksheet)
    } else {
      setValue('autoWorksheet', null)
    }
  }, [])

  const onFormSubmitted = useCallback(() => {
    closeDialogs()
    const data = getValues()
    data.status = Enum.ECreditApplicationStatus.Active
    onSave(data)
  }, [onSave, getValues])

  const onTestSelected = useCallback(
    (applicant: Applicant) => {
      if (tabIndex === 0) {
        reset({ ...getValues(), applicant })
      } else reset({ ...getValues(), coApplicant: applicant })
      closeDialogs()
    },
    [tabIndex],
  )
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <div style={{ height: '100%' }}>
      <SaveDialog onClose={closeDialogs} open={currentOpenDialog === SAVE_DIALOG} onSubmit={onFormSubmitted} />
      <PageSpinner isLoading={isSaving} withBackdrop />
      <TestCaseDialog
        onClose={closeDialogs}
        open={currentOpenDialog === TEST_DIALOG}
        financingProgramId={watch('financingProgramId')}
        onTestCaseSelected={onTestSelected}
      />
      <Paper>
        <PageError errors={error} />
        <form onSubmit={handleSubmit<CreditApplication>(onFormSubmitted, reportErrorToConsole)}>
          <input type="hidden" value=" " {...register('id')} />
          <input type="hidden" value="auto" {...register('financingProgramId')} />
          {!creditApplication.id && (
            <Box sx={{ display: 'flex' }}>
              <SelectComponent
                content={merchants.map((item) => {
                  const data: SelectValueListItem = { label: item.name, value: item.id }
                  return data
                })}
                label={t('common.merchant')}
                {...register('merchantId')}
                error={errors?.merchantId}
              />
            </Box>
          )}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab
                icon={errors.applicant ? <PriorityHighIcon color="error" /> : ''}
                iconPosition="end"
                label={t('editCreditApplication.pannel.applicant')}
              />
              <Tab
                icon={errors.coApplicant ? <PriorityHighIcon color="error" /> : ''}
                iconPosition="end"
                label={t('editCreditApplication.pannel.coapplicant')}
              />
              <Tab
                icon={errors.autoWorksheet ? <PriorityHighIcon color="error" /> : ''}
                iconPosition="end"
                label={t('editCreditApplication.pannel.worksheet')}
              />
              {decision !== null && <Tab iconPosition="end" label={t('editCreditApplication.pannel.decision')} />}

              <Box sx={{ flex: '1 1 auto' }} />
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => openInNewTab(`/credit-applications/${creditApplication.id}/notes`)}
                  sx={{ mr: 2 }}
                >
                  {t('note.note')}
                </Button>

                <Button type="submit" variant="contained" color="primary" value="finale" disabled={isSaving}>
                  {t('common.submitAndPrint')}
                </Button>
              </Box>
            </Tabs>
          </Box>
          <TabPanel value={tabIndex} index={0}>
            {useDevTools && (
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={() => setCurrentOpenDialog(TEST_DIALOG)}
                >
                  Cas de test
                </Button>
              </Box>
            )}
            <ApplicantParameters register={register} errors={errors.applicant} name="applicant" formControl={control} />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <FormControlLabel
              control={<Checkbox checked={hasCoapplicantChecked} onChange={handleToggleCoapplicant} />}
              label={t('editCreditApplication.pannel.coapplicant')}
            />
            {hasCoapplicantChecked && (
              <>
                {useDevTools && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={() => setCurrentOpenDialog(TEST_DIALOG)}
                    >
                      Cas de test
                    </Button>
                  </Box>
                )}
                <ApplicantParameters
                  register={register}
                  errors={errors?.coApplicant as FieldErrors<Applicant>}
                  name="coApplicant"
                  formControl={control}
                />
              </>
            )}
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <FormControlLabel
              control={<Checkbox checked={worksheet !== null} onChange={handleToggleWorksheet} />}
              label={t('editCreditApplication.pannel.worksheet')}
            />
            {worksheet !== null && (
              <WorksheetTab
                worksheet={worksheet}
                setValue={setValue}
                register={register}
                errors={errors?.autoWorksheet as FieldErrors<Worksheet>}
                formControl={control}
              />
            )}
          </TabPanel>
          {decision !== null && (
            <TabPanel value={tabIndex} index={3}>
              <DecisionTab decision={decision} />
            </TabPanel>
          )}
        </form>
      </Paper>
    </div>
  )
}

export default CreditApplicationForm
