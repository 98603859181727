import * as React from 'react'
import { AppBar, Container, Toolbar, IconButton, useTheme } from '@mui/material'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import LanguagePicker from './components/LanguagePicker'
import UserMenu from './components/UserMenu'

type Props = {
  onToggleTheme: () => void
}

export default function Layout({ onToggleTheme }: Props): JSX.Element {
  const theme = useTheme()

  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'rgb(0, 0, 89)', color: 'white' }}>
      <Container maxWidth="xl" disableGutters>
        <Toolbar disableGutters>
          <span style={{ flexGrow: 1 }}>&nbsp;</span>
          <UserMenu />
          <LanguagePicker />
          <IconButton onClick={onToggleTheme}>
            {theme.palette.mode === 'dark' ? (
              <Brightness7Icon sx={{ color: '#ffffff' }} />
            ) : (
              <Brightness4Icon sx={{ color: '#ffffff' }} />
            )}
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
