import React, { useRef, useEffect } from 'react'
import { Box, Grid, Stack, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SendIcon from '@mui/icons-material/Send'
import NoteView from './note'
import { Note } from '../../../data/types'
import { InputTextField } from '../../../components'

type Props = {
  notes: Note[]
  onSave: (data: Partial<Note>) => void
}

function NoteList({ notes, onSave }: Props) {
  const [newNote, setNewNote] = React.useState('')

  const scrollBottomRef = useRef<null | HTMLDivElement>(null)
  const handleSubmit = () => {
    const noteToAdd: Partial<Note> = {
      content: newNote,
    }
    setNewNote('')
    onSave(noteToAdd)
  }
  const { t } = useTranslation()

  useEffect(() => {
    if (scrollBottomRef.current) {
      scrollBottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [notes])

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <Box
          overflow="auto"
          sx={{
            border: '3px solid grey',
            borderRadius: 2,
            p: 1,
            height: '50vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {notes.map((note) => (
            <Box key={note.id} alignSelf={note.isFromIceberg ? 'flex-end' : 'flex-start'}>
              <NoteView key={note.id} note={note} />
            </Box>
          ))}
          <div ref={scrollBottomRef} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mr: 15 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <InputTextField
            value={newNote}
            multiline
            rows={5}
            onChange={(event) => setNewNote(event.target.value)}
            onKeyUp={(event) => {
              if (event.ctrlKey && event.key === 'Enter') handleSubmit()
            }}
            label={t('note.newNote')}
          />
          <Button disabled={newNote === ''} onClick={handleSubmit}>
            <SendIcon />
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}
export default NoteList
