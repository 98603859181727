import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import axios, { AxiosRequestConfig } from 'axios'
import { keyBy } from 'lodash'
import { TRootState } from '..'
import { CompareStrings } from '../../../services/strings'
import type { BusinessError } from '../../types'
import { Merchant } from '../../types/Merchant'

interface AppState {
  globalTechnicalError: string | null
  businessErrors: BusinessError[]
  axiosBaseConfig: AxiosRequestConfig
  merchants: Merchant[]
  language: string
  isInitializing: boolean
}

const initialState: AppState = {
  globalTechnicalError: null,
  businessErrors: [],
  merchants: [],
  language: 'fr',
  axiosBaseConfig: {
    baseURL: process.env.REACT_APP_SIMULATOR_API_URL,
    withCredentials: true,
  },
  isInitializing: true,
}

export const appSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setTechnicalError: (state, action: PayloadAction<string>) => {
      state.globalTechnicalError = action.payload
    },
    setBusinessErrors: (state, action: PayloadAction<BusinessError[]>) => {
      state.businessErrors = action.payload
    },
    setMerchants: (state, action: PayloadAction<Merchant[]>) => {
      state.merchants = action.payload
    },
    resetErrors: (state) => {
      state.businessErrors = []
      state.globalTechnicalError = null
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setJwtToken: (state, action: PayloadAction<string>) => {
      state.axiosBaseConfig.headers = {
        Authorization: `Bearer ${action.payload}`,
      }
    },
    setInitializing: (state, action: PayloadAction<boolean>) => {
      state.isInitializing = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const appActions = appSlice.actions

export default appSlice.reducer

// Other code such as selectors can use the imported `RootState` type

const baseAppSelectors = {
  getTechnicalError: (state: TRootState) => state.app.globalTechnicalError,
  getBusinessError: (state: TRootState) => state.app.businessErrors,
  getAxiosConfig: (state: TRootState) => state.app.axiosBaseConfig,
  getCurrentLang: (state: TRootState) => state.app.language,
  getMerchantList: (state: TRootState) => state.app.merchants,
  getMerchantForProgram: createSelector(
    [(state: TRootState) => state.app.merchants, (state: TRootState, financingProgram: string) => financingProgram],
    (merchants, financingProgram) => {
      if (financingProgram) {
        return merchants
          .filter((merchant) => merchant.supportedFinancingPrograms.includes(financingProgram) && merchant.isActive)
          .sort((a, b) => CompareStrings(a.name, b.name))
      }
      return merchants.filter((merchant) => merchant.isActive)
    },
  ),
  getMerchantsByIds: createSelector(
    (state: TRootState) => state.app.merchants,
    (merchants) => {
      return keyBy(merchants, 'id')
    },
  ),
  getApiClient: createSelector(
    (state: TRootState) => state.app.axiosBaseConfig,
    (config) => {
      return axios.create(config)
    },
  ),
  isLoading: (state: TRootState) => state.app.isInitializing,
}

export const appSelectors = {
  ...baseAppSelectors,
}
