import * as yup from './common/yup-extended'
import yupExtInt from './common/SchemaTypes'
import { Enum } from './constants'
import { AddressSchema, baseAddressSchema } from './AddressSchema'
import { worksheetSchema } from './WorksheetSchema'

const homeFeesSchema = yup.default.object({
  typeId: yup.default.string().notRequired().default('').nullable(true),
  monthlyPayment: yupExtInt.integer.nullable(true).default(null),
  houseMarketValue: yupExtInt.integer.default(null),
  mortgageAmount: yupExtInt.integer.default(null),
  landlord: yup.default.string().notRequired().default(''),
})
export type ApplicantHomeFees = yup.default.InferType<typeof homeFeesSchema>

export const HomeFeesSchema = homeFeesSchema.when((value: ApplicantHomeFees, schema) => {
  if (value?.houseMarketValue || value?.mortgageAmount || value?.typeId || value?.monthlyPayment) {
    schema.fields.typeId = yup.default.string().required()
    schema.fields.monthlyPayment = yupExtInt.integer.nullable(true)
  }
  return schema
})

export const ApplicantAddressSchema = AddressSchema.shape({
  years: yupExtInt.integer,
  months: yupExtInt.integer,
})

export type ApplicantAddress = yup.default.InferType<typeof ApplicantAddressSchema>

export const JobSchema = yup.default.object({
  id: yup.default.string().default(null),
  employerName: yup.default.string().required(),
  jobTitle: yup.default.string(),
  jobType: yupExtInt.integer.required(),
  annualSalary: yupExtInt.integer.required(),
  employerPhone: yup.default.string().IsValidCanadianPhone(),
  address: AddressSchema,
  years: yupExtInt.integer,
  months: yupExtInt.integer,
  verificationContactName: yup.default.string().nullable(),
  verificationContactRole: yup.default.string().nullable(),
  verificationContactPhone: yup.default.string().nullable(),
})
export type ApplicantJob = yup.default.InferType<typeof JobSchema>

export const OtherIncomeSchema = yup.default.object({
  id: yup.default.string().default(null),
  typeId: yupExtInt.integer.required(),
  description: yup.default.string().nullable(true).when('typeId', {
    is: Enum.EOtherIncomeTypes.other,
    then: yup.default.string().required(),
  }),
  annualAmount: yupExtInt.integer.required(),
})
export type ApplicantOtherIncome = yup.default.InferType<typeof OtherIncomeSchema>

export const additionnalBankruptciesSchema = yup.default.object({
  id: yup.default.string().default(null),
  filedD: yup.default.date().required(),
  liabilityAmount: yupExtInt.integer.required(),
  intentCode: yup.default.string().required(),
  caseNumber: yup.default.string().required(), // It's the name of the trustee
})
export type AdditionnalBankruptcies = yup.default.InferType<typeof additionnalBankruptciesSchema>

const draftApplicantSchema = {
  id: yup.default.string().nullable().default(null), // le default null est nécessaire pour le createEntityAdapter
  middleName: yup.default.string().default(''),
  relationWithApplicant: yup.default.string().default(''),
  firstName: yup.default.string().required().default(''),
  lastName: yup.default.string().required().default(''),
  birthDate: yup.default.string().default('').isValidDate(),
  sin: yup.default.string().SinType().default(''),
  languageId: yupExtInt.integer.nullable(false),
  genderId: yupExtInt.integer.default(null),
  maritalStatusId: yupExtInt.integer.nullable(true),
  homePhone: yup.default.string().default('').IsValidCanadianPhone(),
  cellPhone: yup.default.string().default('').IsValidCanadianPhone(),
  email: yup.default.string().notRequired().email().default(''),
  // Incomes
  currentJobs: yup.default.array(JobSchema).default([]),
  previousJobs: yup.default.array(JobSchema).default([]),
  otherIncomes: yup.default.array(OtherIncomeSchema).default([]),
  // address
  currentAddress: ApplicantAddressSchema.default(ApplicantAddressSchema.getDefault()),
  previousAddresses: yup.default.array<ApplicantAddress>().of(ApplicantAddressSchema).default([]),
  homeFees: HomeFeesSchema.notRequired().default(HomeFeesSchema.getDefault()),
}

const DraftApplicantSchema = yup.default.object(draftApplicantSchema)

const fullAddressSchema = { ...baseAddressSchema }
fullAddressSchema.civicNumber = fullAddressSchema.civicNumber.required()
fullAddressSchema.street = fullAddressSchema.street.required()
fullAddressSchema.city = fullAddressSchema.city.required()
fullAddressSchema.postalCode = fullAddressSchema.postalCode.required().IsValidCanadianPostalCode()
fullAddressSchema.stateIso = fullAddressSchema.stateIso.required()
fullAddressSchema.country = fullAddressSchema.country.required()

const fullApplicantSchema = { ...draftApplicantSchema }
fullApplicantSchema.birthDate = fullApplicantSchema.birthDate.required().isValidDate()
fullApplicantSchema.genderId = fullApplicantSchema.genderId.required()
fullApplicantSchema.languageId = fullApplicantSchema.languageId.required()
fullApplicantSchema.maritalStatusId = fullApplicantSchema.maritalStatusId.required()
fullApplicantSchema.cellPhone = fullApplicantSchema.cellPhone.IsValidCanadianPhone().required()
fullApplicantSchema.email = fullApplicantSchema.email.required()
fullApplicantSchema.currentAddress = yup.default.object(fullAddressSchema)

export const FullApplicantSchema = yup.default.object(fullApplicantSchema)

const draftCreditApplicationSchema = {
  id: yup.default.string().default(''), // le default null est nécessaire pour le createEntityAdapter
  financingProgramId: yup.default.string().required(),
  merchantId: yupExtInt.integer.required(),
  status: yup.default.string().required().default(Enum.ECreditApplicationStatus.Draft),
  applicant: DraftApplicantSchema.required().default(DraftApplicantSchema.getDefault()),
  coApplicant: DraftApplicantSchema.nullable().default(null),
  autoWorksheet: worksheetSchema.nullable(true).default(null),
}

const fullCreditApplicationSchema = { ...draftCreditApplicationSchema }
fullCreditApplicationSchema.merchantId = fullCreditApplicationSchema.merchantId.required()
fullCreditApplicationSchema.applicant = FullApplicantSchema.required()
fullCreditApplicationSchema.coApplicant = FullApplicantSchema.nullable().default(null)
export const FullCreditApplicationSchema = yup.default.object(fullCreditApplicationSchema)

export type Applicant = yup.default.InferType<typeof DraftApplicantSchema>

const creditApplicationType = {
  ...draftCreditApplicationSchema,
  currentRevisionId: yup.default.string(),
  dtReferenceId: yup.default.string().default(''),
  createdOn: yup.default.date().required().default(new Date()),
  updatedOn: yup.default.date().required().default(new Date()),
  expiresOn: yup.default.date().required().default(new Date()),
  createdByUserFullname: yup.default.string().required().default(''),
  createdByUserLanguageId: yupExtInt.integer.nullable(false),
}

const CreditApplicationTypeSchema = yup.default.object(creditApplicationType)

export type CreditApplication = yup.default.InferType<typeof CreditApplicationTypeSchema>
