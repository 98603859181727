import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useAppSelector, useSideEffect } from '../../data/store'
import { creditSelectors, creditEffects } from '../../data/store/CreditApplication'
import { noteSelectors, noteEffects } from '../../data/store/Note'
import NoteList from './components/noteList'
import { Note } from '../../data/types'
import NoteHeader from './components/noteHeader'
import { appSelectors } from '../../data/store/AppStore'

export default function NotePage(): JSX.Element | null {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatchEffect = useSideEffect()

  const currentApplication = useAppSelector(creditSelectors.getCurrent)
  const notes = useAppSelector(noteSelectors.getNoteList)
  const merchantsByIds = useAppSelector(appSelectors.getMerchantsByIds)

  useEffect(() => {
    if (id) {
      dispatchEffect(creditEffects.getById(id)).catch(() => {
        navigate('/notfound', { replace: true })
      })
    }
  }, [dispatchEffect, id])

  useEffect(() => {
    if (currentApplication) {
      dispatchEffect(noteEffects.getNotesForCreditApplicationId(currentApplication.dtReferenceId))
      document.title = `${currentApplication.applicant.firstName} ${currentApplication.applicant.lastName}`
    }
  }, [currentApplication])

  const onSave = (data: Partial<Note>) => {
    data.dtReferenceId = currentApplication?.dtReferenceId
    dispatchEffect(noteEffects.createNote(data))
  }
  return (
    <div style={{ marginBottom: 25 }}>
      <Grid container item xs={12} spacing={2} alignItems="center">
        {currentApplication && <NoteHeader merchantsByIds={merchantsByIds} currentApplication={currentApplication} />}
        <Grid item xs={12}>
          <NoteList notes={notes} onSave={onSave} />
        </Grid>
      </Grid>
    </div>
  )
}
