import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { omit } from 'lodash'
import { AxiosInstance } from 'axios'
import type { User } from '../../types'
import type { TRootState } from '..'
import { LoginCredentials } from '../../types/LoginCredentials'
import api from '../../api'
import { apiStateActions } from '../ApiStateStore'
import { appActions } from '../AppStore/app-store'
import { userActions, LOGIN } from './user-store'

type UserDto = {
  id: string
  username: string
  name: string
  roles: string
  langId: string
  exp: number /* expire date in ms. to get the date, do new Date(user.exp * 1000) */
}

function parseJwt(token: string): object {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

function loadUserFromJwtString(jwtToken: string): User {
  const dto = parseJwt(jwtToken) as UserDto
  const user: User = {
    ...omit(dto, ['roles']),
    roles: dto.roles.split(','),
  }
  return user
}

function saveUserToStorage(token: string): void {
  localStorage.setItem('jwtToken', token)
}

export const userEffects = {
  loadUser: () => async (client: AxiosInstance, dispatch: ThunkDispatch<TRootState, undefined, AnyAction>) => {
    let user: User | null = null
    const jwtToken = localStorage.getItem('jwtToken')

    if (jwtToken) {
      user = loadUserFromJwtString(jwtToken)

      if (new Date(user.exp * 1000) <= new Date()) {
        user = null
      } else {
        dispatch(userActions.setUser(user))
        dispatch(appActions.setJwtToken(jwtToken))
      }
    }

    if (!user) {
      dispatch(userActions.setMustAuthenticate(true))
    }
    return Promise.resolve(user)
  },
  login: (data: LoginCredentials) => {
    return async (client: AxiosInstance, dispatch: ThunkDispatch<TRootState, undefined, AnyAction>) => {
      try {
        // log api is loading
        dispatch(apiStateActions.logApiCallInitiated(LOGIN))
        dispatch(appActions.setBusinessErrors([]))

        // perform actual request
        const token = await api.user.login(client, data)
        saveUserToStorage(token.jwt)
        const user = loadUserFromJwtString(token.jwt)

        dispatch(appActions.setJwtToken(token.jwt))
        dispatch(userActions.setUser(user))
        return user
      } finally {
        dispatch(apiStateActions.logApiCallCompleted(LOGIN))
      }
    }
  },
}
