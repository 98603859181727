import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { store } from './data/store'
import { appActions } from './data/store/AppStore'
import reportWebVitals from './reportWebVitals'
import App from './containers/App/App'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'fr',
    debug: false,
    supportedLngs: ['fr', 'en'],
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    store.dispatch(appActions.setLanguage(i18n.language))
  })

export default i18n

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
