export class Enum {
  static readonly EPaymentFrequency = {
    Monthly: 'monthly',
    BiWeekly: 'biWeekly',
  }

  static readonly ECarCondition = {
    New: 'new',
    Used: 'used',
  }

  static readonly EHomeStatus = {
    Owner: 'own',
    Renter: 'rent',
    OwnMobileHome: 'ownMobileHome',
    WithParents: 'withParents',
    ReserveHousing: 'reserveHousing',
    OwnWithMortgage: 'ownWithMortgage',
    Other: 'other',
  }

  static readonly ECountry = {
    Canada: 'Canada',
  }

  static readonly ETasksStatus = {
    Completed: 'completed',
    Cancelled: 'cancelled',
    ToDo: 'todo',
  }

  static readonly EDocumentStatus = {
    AwaitingDocument: 'awaitingDocument',
    AwaitingScan: 'awaitingScan',
    AwaitingApproval: 'awaitingApproval',
    Deleted: 'deleted',
    Cancelled: 'cancelled',
    Refused: 'refused',
    Approved: 'approved',
  }

  static readonly EApplicants = {
    Applicant: 'applicant',
    CoApplicant: 'coApplicant',
  }

  static readonly ETaskType = {
    RegisterToRDPRM: 0,
    VerifyCreditBankruptcySyndic: 1,
    VerifyFinancingBankruptcySyndic: 2,
    VerifyEmployment: 3,
    VerifyDocument: 4,
    OtherTask: 5,
    VerifyMultipleEquifaxFiles: 6,
    VerifyDateOfBirth: 7,
    VerifyNas: 8,
    ReviewCreditFile: 9,
    ReviewWorksheet: 10,
    AnalyseCredit: 11,
    VerifyBankruptcyHistory: 12,
    ConfirmOtherIncome: 13,
    VerifyStolenOrLostIdentity: 14,
    VerifyAddressMisuse: 15,
    VerifyFraudAlert: 16,
    VerifyCoapplicantJob: 17,
    VerifyPreviousEmployment: 18,
    VerifyCoapplicantPreviousJob: 19,
    ValidateClientIdentity: 20,
    VerifyCar: 21,
    VerifyCity: 22,
    ConfirmJobIncome: 23,
    ConfirmIncome: 24,
  }

  static readonly EDocumentType = {
    AlimonyCourtJudgment: 0,
    BackOfTheDriversLicense: 1,
    BandCouncilCard: 2,
    BankruptcyTrusteeForm: 3,
    Bill134FormAndCreditBureau: 4,
    BorrowerAutoInsuranceProofMentioningIceberg: 5,
    CarfaxLessThanMonthsAndLessThanAmount: 6,
    CompletedAndSignedCreditApplication: 7,
    ContractForOtherInsuranceProductsFinanced: 8,
    DealerRecognitionProgramAppendix: 9,
    DealerResponsibilityLetter: 10,
    DebtRatioAppendix: 11,
    ElectronicSignatureAudit: 12,
    EmployerForm: 13,
    EmploymentConfirmation: 14,
    ExtendedWarrantyContract: 15,
    IcebergContract: 16,
    LastPayStubJob: 17,
    LastPayStubsJobTwo: 18,
    LienReleaseFinancedVehicleOne: 19,
    LienReleaseFinancedVehicleTwo: 20,
    MaternityLeaveBenefit: 21,
    Note: 22,
    Passport: 23,
    ProofOfResidence: 24,
    ProofOfBenefits: 25,
    ProofOfDisabilityInsuranceBenefits: 26,
    ProofOfInsolvancyCompletion: 27,
    ProofOfRelease: 28,
    ProofOfSourceOfFunds: 29,
    ProofOfVehicleRegistration: 30,
    References: 31,
    RepaymentCapacityEvaluationFormAppendix: 32,
    SecondIdWithPhoto: 33,
    SignedWindowSticker: 34,
    SinCardOrOfficialDocumentWithSin: 35,
    SixMonthsPersonalBankStatements: 36,
    T4: 37,
    T4E: 38,
    ThreeMonthsPersonalBankStatements: 39,
    Transit: 40,
    ValidDriverLicense: 41,
    VehiclePurchaseContract: 42,
    VehicleValueEvaluation: 43,
    VoidCheque: 44,
    WorkPermit: 45,
    Other: 46,
  }

  static readonly ECreditApplicationSteps = {
    Financing: 'financing',
    Credit: 'credit',
    PayOut: 'payout',
    CVT: 'CVT',
  }

  static readonly EGender = {
    men: 0,
    woman: 1,
  }

  static readonly EJobType = {
    fullTime: 0,
    partTime: 1,
    Seasonal: 3,
    SelfEmployed: 5,
  }

  static readonly ELanguage = {
    french: 0,
    english: 1,
  }

  static readonly EMaritalStatus = {
    single: 0,
    married: 1,
    deFactoCouple: 2,
    widowWidower: 3,
    undefined: 999,
  }

  static readonly EOtherIncomeTypes = {
    familyAllocations: 1,
    workIncidentIndemnisation: 3,
    maternityPaternityIncome: 4,
    other: 6,
    retirementIncome: 8,
    disabilityIncome: 11,
  }

  static readonly EProvince = {
    quebec: 'QC',
    ontario: 'ON',
    novaScotia: 'NS',
    newBrunswick: 'NB',
    princeEdwardIsland: 'PE',
    newfoundland: 'NL',
  }

  static readonly EFinancingProgram = {
    Auto: 'auto',
    IfXpress: 'ifxpress',
    Vlvr: 'vlvr',
    Products: 'products',
  }

  static readonly EPlanType = {
    gold: 'autoGold',
    silver: 'autoSilver',
    bronze: 'autoBronze',
    goldFs: 'autoGoldFS',
    bronzeFs: 'autoBronzeFS',
  }

  static readonly ETermMax = {
    six: 6,
    twelve: 12,
    eighteen: 18,
    twentyFour: 24,
    thirty: 30,
    thirtySix: 36,
    fortyTwo: 42,
    fortyEight: 48,
    fiftyFour: 54,
    sixty: 60,
    sixtySix: 66,
    seventyTwo: 72,
    seventyEight: 78,
    eightyFour: 84,
  }

  static readonly EDecision = {
    pending: 'pending',
    accept: 'approved',
    manualRevision: 'manual-revision',
    declined: 'refused',
  }

  static readonly ECreditApplicationStatus = {
    Draft: 'draft',
    Active: 'active',
    Completed: 'completed',
    Cancelled: 'cancelled',
  }

  static readonly ETax = {
    gst: 'gst',
    pst: 'pst',
    hst: 'hst',
    qst: 'qst',
  }

  static readonly EMask = {
    phoneNumberMask: '000-000-0000',
    sinMask: '000 000 000',
    postalCode: 'a0a 0a0',
  }

  static readonly EBankruptcyTypes = {
    OngoingBankruptcy: 'F',
    BankruptcyReleased: 'A',
    OngoingConsumerProposal: 'G',
    ConsumerProposalReleased: 'J',
  }
}

export const Constants = {
  GetMinIncomeForProgram: (program: string) => {
    if (Enum.EFinancingProgram.Vlvr === program) return 20000
    return 21000
  },
}

// doit être fait en deux lignes sinon webpack optimise et n'inclut pas la string
// de plus, le toString est requis car en local c'est un string alors qu'en dev c'est une string
const useDevToolsStr = process.env.REACT_APP_USE_DEV_TOOLS ?? 'false'
export const useDevTools = useDevToolsStr.toString() === 'true'
