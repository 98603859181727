import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import appReducer from './AppStore/app-store'
import userReducer from './UserStore/user-store'
import creditReducer from './CreditApplication/credit-application-store'
import apiStateReducer from './ApiStateStore'
import vehicleReducer from './Vehicle/vehicle-store'
import noteReducer from './Note/note-store'
import decisionReducer from './Decision/decision-store'

export { useSideEffect } from './effects/side-effects'

export function InitializeStore() {
  return configureStore({
    reducer: {
      app: appReducer,
      user: userReducer,
      credit: creditReducer,
      api: apiStateReducer,
      vehicle: vehicleReducer,
      note: noteReducer,
      decision: decisionReducer,
    },
  })
}

export const store = InitializeStore()

export type TRootState = ReturnType<typeof store.getState>
export type TAppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<TAppDispatch>()
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector
