import { Note } from '../../types'
import allApi from '../../api'
import { makeSave, makeGetListWithIds } from '../effects/standard-side-effects'
import { noteSlice, GET_NOTE, SAVE_NOTE } from './note-store'

export const noteEffects = {
  getNotesForCreditApplicationId: (dtReferenceId: string) =>
    makeGetListWithIds<Note>(GET_NOTE, allApi.note.getNoteList, noteSlice.actions.setNoteList, { dtReferenceId }),
  createNote: (data: Partial<Note>) => makeSave<Note>(SAVE_NOTE, allApi.note.create, noteSlice.actions.addNote, data),
}
