import { PayloadAction, createReducer, AnyAction } from '@reduxjs/toolkit'
import { TRootState } from '.'

const INITIATE_CALL = 'initiate api call'
const COMPLETED_CALL = 'completed api call'

type TState = {
  apiCallStates: Record<string, boolean>
}

const initialState: TState = {
  apiCallStates: {},
}

export interface IApiLogInfo {
  apiName: string
}

function isApiCall(action: PayloadAction<AnyAction>): boolean {
  return action.type.startsWith(INITIATE_CALL) || action.type.startsWith(COMPLETED_CALL)
}

export default createReducer(initialState, (builder) => {
  builder.addMatcher(isApiCall, (state: TState, action: PayloadAction<IApiLogInfo>) => {
    state.apiCallStates[action.payload.apiName] = action.type.startsWith(INITIATE_CALL)
  })
})

const logApiCallInitiated = (apiName: string): PayloadAction<IApiLogInfo> => {
  return {
    type: `${INITIATE_CALL} ${apiName}`,
    payload: {
      apiName,
    },
  }
}

const logApiCallCompleted = (apiName: string): PayloadAction<IApiLogInfo> => {
  return {
    type: `${COMPLETED_CALL} ${apiName}`,
    payload: {
      apiName,
    },
  }
}

export const apiStateActions = {
  logApiCallInitiated,
  logApiCallCompleted,
}

export const apiStateSelectors = {
  isLoading: (state: TRootState, apiName: string) => state.api.apiCallStates[apiName] === true,
}
