import React from 'react'
import { Toolbar } from '@mui/material'
import MainActions from './MainActions'

export default function DataGridHeader(): JSX.Element {
  return (
    <Toolbar sx={{ m: 3, justifyContent: 'space-between' }}>
      <MainActions />
    </Toolbar>
  )
}
