/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react'
import { BrowserRouter as Router, Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { Container, createTheme, CssBaseline, LinkProps, ThemeProvider } from '@mui/material'
import { blue } from '@mui/material/colors'
import AppHeader from './AppHeader'
import TopLevelRoutes from './Routes'
import { appStoreEffects } from '../../data/store/AppStore'
import { useAppSelector, useSideEffect, useAppDispatch } from '../../data/store'
import { userEffects, userSelectors, userActions } from '../../data/store/UserStore'
import LoginPage from '../LoginPage/LoginPage'

// eslint-disable-next-line react/display-name
const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />
  },
)

export default function App(): JSX.Element | null {
  const dispatch = useAppDispatch()
  const useDarkMode = useAppSelector(userSelectors.isDarkModeEnabled)
  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiLink: {
            defaultProps: {
              component: LinkBehavior,
            } as LinkProps,
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
            },
          },
        },
        palette: {
          primary: { main: useDarkMode ? blue[200] : blue[700] },
          background: {
            default: useDarkMode ? '#1e1e1e' : '#ffffff',
          },
          mode: useDarkMode ? 'dark' : 'light',
        },
      }),
    [useDarkMode],
  )

  const [isLoaded, setLoaded] = useState(false)
  const dispatchEffect = useSideEffect()
  const needAuth = useAppSelector(userSelectors.needToAuthenticate)

  useEffect(() => {
    dispatch(userActions.loadPreferences())
    Promise.all([dispatchEffect(appStoreEffects.initialize()), dispatchEffect(userEffects.loadUser())]).then(() =>
      setLoaded(true),
    )
  }, [])

  if (!isLoaded) return null
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        {needAuth && <LoginPage />}
        {!needAuth && (
          <>
            <AppHeader onToggleTheme={() => dispatch(userActions.setDarkModePreference(!useDarkMode))} />
            <Container maxWidth="xl" disableGutters>
              <TopLevelRoutes />
            </Container>
          </>
        )}
      </Router>
    </ThemeProvider>
  )
}
