import i18next from 'i18next'
import { Address, Vehicle } from '../data/types'

export function FormatCurrencyNoDecimals(data?: number | null) {
  const { language } = i18next
  const val = data?.toLocaleString(`${language}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return val !== undefined && val !== null ? val : ''
}

export function FormatCurrency(data?: number | string | null | undefined) {
  const { language } = i18next
  let amount = 0
  if (data) {
    amount = parseFloat(data.toString())
  }
  const positifAmount = amount < 0 ? amount * -1 : amount
  let val = positifAmount.toLocaleString(`${language}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
  val = amount < 0 ? `(${val})` : val
  return val !== undefined && val !== null ? val : ''
}

export function formatNumber(data?: number | null | undefined) {
  const { language } = i18next
  const val = data?.toLocaleString(`${language}-CA`, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
  return val !== undefined && val !== null ? val : ''
}

export const formatDateTime = (date: Date | null | undefined) =>
  !date ? '' : new Date(date).toLocaleString('en-CA', { hour12: true })

export const formatDate = (date: Date | null | string | undefined) => {
  if (!date) return ''
  let dateString = date.toString()

  if (dateString.split('T') && dateString.split('T').length === 1) dateString = dateString.concat('T00:00:00')
  if (Date.parse(dateString)) return new Date(dateString).toLocaleDateString('en-CA')

  return date
}

export const formatAddress = (address: Address) => {
  let ret = ''
  if (address.civicNumber) ret = ret.concat(address.civicNumber)
  if (address.street) ret = ret.concat(` ${address.street}`)
  if (address.city) ret = ret.concat(`, ${address.city}`)
  if (address.stateIso) ret = ret.concat(`, ${address.stateIso}`)
  if (address.country) ret = ret.concat(`, ${address.country}`)
  if (address.postalCode) ret = ret.concat(`, ${address.postalCode?.toUpperCase()}`)
  return ret
}

export const formatCar = (vehicle: Vehicle | undefined | null) => {
  let ret = ''
  if (vehicle) {
    if (vehicle.make) ret = ret.concat(vehicle.make)
    if (vehicle.year) ret = ret.concat(` ${vehicle.year}`)
    if (vehicle.model) ret = ret.concat(` ${vehicle.model}`)
    if (vehicle.bodyStyle) ret = ret.concat(` ${vehicle.bodyStyle}`)
    if (vehicle.additions) ret = ret.concat(` ${vehicle.additions.map((value) => ` ${value}`)}`)
    if (vehicle.includes) ret = ret.concat(` ${vehicle.includes.map((value) => ` ${value}`)}`)
    if (vehicle.series) ret = ret.concat(` ${vehicle.series}`)
  }
  if (!vehicle) return ''
  return ret
}
