import creditApplicationApi from './credit-application/credit-application-api'
import configApi from './config/config-api'
import userApi from './user/user-api'
import vehicleApi from './vehicle/vehicle-api'
import noteApi from './note/note-api'
import decisionApi from './decision/decision-api'

export default {
  credit: creditApplicationApi,
  config: configApi,
  user: userApi,
  vehicle: vehicleApi,
  note: noteApi,
  decision: decisionApi,
}
