import React from 'react'
import { Breadcrumbs, Link, Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

type Props = { trees: Array<{ path: string; label: string }> }

function Breadcrumb({ trees }: Props): JSX.Element {
  return (
    <nav>
      <Breadcrumbs maxItems={4} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {trees.map((value, index: number) => {
          return index === trees.length - 1 ? (
            <Typography key={value.label} textTransform="uppercase" component="span">
              <h1>{value.label}</h1>
            </Typography>
          ) : (
            <Link
              key={value.label}
              href={value.path}
              style={{
                textTransform: 'uppercase',
                textDecoration: 'none',
              }}
            >
              <h1>{value.label}</h1>
            </Link>
          )
        })}
      </Breadcrumbs>
    </nav>
  )
}

export default Breadcrumb
