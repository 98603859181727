import { TextField, InputBaseComponentProps, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldError } from 'react-hook-form'
import translateErrorCode from './TranslateError'
import { SelectValueListItem } from '../data/types/SelectValueListItem'

interface Props extends InputBaseComponentProps {
  error?: FieldError
  content: Array<SelectValueListItem>
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SelectComponent = React.forwardRef<Props, Props>(function SelectComponent(props: Props, ref: React.Ref<any>) {
  const { t } = useTranslation()

  const { error, content, ...otherProps } = props

  const theme = useTheme()

  return (
    <TextField
      inputRef={ref}
      label={otherProps.label}
      error={error !== undefined}
      helperText={error ? t(translateErrorCode(error)) : ' '}
      variant="outlined"
      fullWidth
      select
      InputLabelProps={{ shrink: true }}
      SelectProps={{
        native: true,
        inputProps: { ...otherProps },
      }}
    >
      <option aria-label="None" value="" style={{ display: 'none' }} hidden />
      {content.map((item) => (
        <option
          style={{
            color: item.label.toString().includes('**') ? theme.palette.grey[500] : 'none',
          }}
          key={item.value}
          value={item.value}
        >
          {t(item.label.toString())}
        </option>
      ))}
    </TextField>
  )
})

SelectComponent.defaultProps = {
  error: undefined,
}
export default SelectComponent
