import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import axios, { AxiosRequestConfig } from 'axios'
import React, { useEffect, useState } from 'react'

type DialogProps = {
  onMockedLoginSelected: (username: string) => void
  open: boolean
  onClose: () => void
}

type MockedLogin = {
  id: string
  username: string
  roles: string[]
}

type MockedLoginItemProps = {
  onTestCaseSelected: (username: string) => void
  mockedLogin: MockedLogin
  selected: boolean
}

function loadMockedLogins(): Promise<MockedLogin[]> {
  const payload: AxiosRequestConfig = {
    method: 'GET',
    baseURL: process.env.REACT_APP_SIMULATOR_API_URL,
    url: '/MockLogin',
  }
  return axios.request(payload).then((response) => {
    const res = response.data as MockedLogin[]
    res.forEach((x: MockedLogin, index) => {
      x.id = index.toString()
    })
    return response.data as MockedLogin[]
  })
}

function TestCaseItem({ onTestCaseSelected, mockedLogin, selected }: MockedLoginItemProps) {
  return (
    <>
      <ListItemButton onClick={() => onTestCaseSelected(mockedLogin.username)} selected={selected}>
        <ListItemText primary={mockedLogin.username} secondary={mockedLogin.roles.join(', ')} />
      </ListItemButton>
      <Divider />
    </>
  )
}

function ListMockedLoginDialog({ open, onClose, onMockedLoginSelected }: DialogProps) {
  const [selectedUsername, setSelectedUsername] = useState<string | null>(null)
  const [mockedLogins, setTestCases] = useState<MockedLogin[]>([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (open && mockedLogins.length === 0) {
      setLoading(true)
      loadMockedLogins().then((values: MockedLogin[]) => {
        setLoading(false)
        setTestCases(values)
      })
    }
  }, [open])

  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="xl">
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title">
        Sélectionner un mock pour le login
      </DialogTitle>
      <DialogContent>
        <Box height={400} width={900}>
          {isLoading && <CircularProgress />}
          {!isLoading && (
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {mockedLogins.map((login) => (
                <TestCaseItem
                  key={login.id}
                  mockedLogin={login}
                  onTestCaseSelected={setSelectedUsername}
                  selected={login.username === selectedUsername}
                />
              ))}
            </List>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
          <Button type="button" variant="contained" color="error" onClick={onClose}>
            Annuler
          </Button>
        </Box>
        <Box sx={{ flex: '1 1 auto' }} />
        <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
          <Button
            type="button"
            variant="contained"
            color="success"
            disabled={selectedUsername === null}
            onClick={() => {
              if (selectedUsername) onMockedLoginSelected(selectedUsername)
            }}
          >
            Charger
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ListMockedLoginDialog
