/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Enum } from '../../../../../../../data/types/constants'
import { SelectComponent, InputTextField, InputTextFieldWithMask } from '../../../../../../../components'
import { SelectValueListItem } from '../../../../../../../data/types'

type Props = {
  register: UseFormRegister<any>
  errors?: Record<string, FieldError>
  name: string
  isCommercial?: boolean
  formControl: Control<any>
}

function AddressComponent({ register, errors, name, isCommercial, formControl }: Props) {
  const { t } = useTranslation()

  return (
    <Grid container item xs={12} spacing={2} alignContent="space-between">
      <input type="hidden" {...register(`${name}.country`)} value="Canada" />
      <Grid item xs={12} md={3}>
        <InputTextField
          error={errors?.civicNumber}
          label={t('editCreditApplication.address.civicNumber')}
          {...register(`${name}.civicNumber`)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputTextField
          error={errors?.street}
          label={t('editCreditApplication.address.street')}
          {...register(`${name}.street`)}
        />
      </Grid>
      {!isCommercial && (
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.apartment}
            label={t('editCreditApplication.address.apartment')}
            {...register(`${name}.apartment`)}
          />
        </Grid>
      )}
      {isCommercial && (
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.suite}
            label={t('editCreditApplication.address.suite')}
            {...register(`${name}.suite`)}
          />
        </Grid>
      )}
      <Grid item xs={12} md={3}>
        <InputTextField
          error={errors?.city}
          label={t('editCreditApplication.address.city')}
          {...register(`${name}.city`)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Controller
          name={`${name}.postalCode`}
          control={formControl}
          render={({ field }) => (
            <InputTextFieldWithMask
              mask={Enum.EMask.postalCode}
              error={errors?.postalCode}
              label={t('editCreditApplication.address.postalCode')}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectComponent
          content={Object.values(Enum.EProvince)
            .sort()
            .map((item) => {
              const data: SelectValueListItem = { label: `enum.eProvince.${item}`, value: item }
              return data
            })}
          label={t('editCreditApplication.address.province')}
          {...register(`${name}.stateIso`)}
          error={errors?.stateIso}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputTextField
          error={errors?.years}
          label={t('editCreditApplication.address.since.years')}
          {...register(`${name}.years`)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputTextField
          error={errors?.months}
          label={t('editCreditApplication.address.since.months')}
          {...register(`${name}.months`)}
        />
      </Grid>
    </Grid>
  )
}

AddressComponent.defaultProps = {
  isCommercial: false,
  errors: undefined,
}

export default AddressComponent
