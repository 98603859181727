import * as yup from 'yup'
import { Decimal } from 'decimal.js'

const integer = yup
  .number()
  .transform((_, val) => {
    if (val === '' || val === null || val === undefined) return null
    const n = Number(val)
    if (Number.isInteger(n)) return n
    return NaN
  })
  .default(null)
  .nullable(true)
  .typeError('common.errors.number')

const double = yup
  .number()
  .transform((_, val) => {
    if (val === '' || val === null || val === undefined) {
      return null
    }
    const x = Number(val.toString().replace(',', '.'))
    const n = Number(new Decimal(x).mul(100).trunc().div(100))
    if (Number.isFinite(n)) return n
    return NaN
  })
  .default(null)
  .nullable(true)
  .typeError('common.errors.number')

export default {
  integer,
  double,
}
