import { Merchant } from '../../types'
import { makeGetListFromApi, makeGetSingleFromApi } from '../make-api'

export type ValueListItemDto = {
  value: string
  textFr: string
  textEn: string
  priority?: number
  active: boolean
}

export type ValueListDto = {
  id: string
  sortMethod: 'priority' | 'alpha'
  defaultvalue: string | null
  items: ValueListItemDto[]
  expiresOn: number
}

const configApi = {
  getMerchants: makeGetListFromApi<Merchant>('/Merchants'),
  getValueList: makeGetSingleFromApi<ValueListDto>(
    'configs/v1/ValueList/{listId}/values',
    undefined,
    process.env.REACT_APP_CONFIG_API_URL,
  ),
}

export default configApi
