import React from 'react'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { Box, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ApplicantInformationParameters from './components/applicantInformationComponent'
import AddressComponent from './components/AddressComponent'
import ApplicantMortgage from './components/applicantMortgageComponent'
import ApplicantIncomeSection from './components/applicantIncomeComponent'
import { Applicant, CreditApplication } from '../../../../../../data/types'

type Props = {
  register: UseFormRegister<CreditApplication>
  errors: FieldErrors<Applicant> | undefined
  name: 'coApplicant' | 'applicant'
  formControl: Control<CreditApplication>
}

function ApplicantParameters({ register, errors, name, formControl }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <ApplicantInformationParameters register={register} errors={errors} name={name} formControl={formControl} />
      <Box
        sx={{
          height: 20,
        }}
      />
      <Divider>
        <Typography variant="h4" component="span" gutterBottom>
          {t('editCreditApplication.address.label')}
        </Typography>
      </Divider>
      <AddressComponent
        formControl={formControl}
        register={register}
        errors={errors?.currentAddress}
        name={`${name}.currentAddress`}
      />
      <ApplicantMortgage register={register} errors={errors?.homeFees} name={`${name}.homeFees`} />
      <ApplicantIncomeSection register={register} formControl={formControl} errors={errors?.otherIncomes} name={name} />
    </>
  )
}
export default ApplicantParameters
